import { reducerWithInitialState } from 'typescript-fsa-reducers';
import immutable from 'immutability-helper';
import { DocumentActions } from '../../actions/utils/generate-document-actions';
import firebase from 'firebase/app';
import { Entity } from '../../../domain/utils/entity';
import { throwInTsx } from '../../../components/utils/throw-in-tsx';
import isNil from 'lodash/isNil';
import { Properties } from '../../../utils/types';

export interface DocumentState<DocumentType extends Entity> {
  loading?: boolean;
  error?: any;
  document?: firebase.firestore.DocumentSnapshot<DocumentType>;
  entity?: DocumentType;
  writeDocumentId?: string;
  updateDocumentId?: string;
  deleteDocumentId?: string;
  subscribed?: boolean;
}

export interface DocumentStates<DocumentType extends Entity>
  extends Properties<DocumentState<DocumentType>> {}

export const initialDocumentState = {
  loading: false,
  error: undefined,
  document: undefined,
  entity: undefined,
  writeDocumentId: undefined,
  updateDocumentId: undefined,
  deleteDocumentId: undefined,
  subscribed: false,
};

export const generateDocumentReducer = <
  DocumentType extends Entity,
  ErrorType extends Error = Error
>(
  actions: DocumentActions<DocumentType, ErrorType>,
  initialState: DocumentState<DocumentType> = initialDocumentState
) =>
  (reducerWithInitialState<DocumentStates<DocumentType>>({})
    .case(actions.read.started, (state, action) => {
      return immutable(state, {
        [action.id]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: true,
              error: undefined,
              document: undefined,
              entity: undefined,
              subscribed: false,
            },
          }),
      });
    })
    .case(actions.read.done, (state, action) => {
      return immutable(state, {
        [action.params?.id ?? 'document']: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              document: action.result,
              entity: action.result.data(),
              error: undefined,
            },
          }),
      });
    })
    .case(actions.read.failed, (state, action) => {
      return immutable(state, {
        [action.params?.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              document: undefined,
              entity: undefined,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.write.started, (state, action) => {
      return immutable(state, {
        [action.id]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: true,
              writeDocumentId: undefined,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.write.done, (state, action) => {
      return immutable(state, {
        [action.result]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              writeDocumentId: action.result,
              entity: action.params,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.write.failed, (state, action) => {
      return immutable(state, {
        [action.params?.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              writeDocumentId: undefined,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.writeTransaction.started, (state, action) => {
      return immutable(state, {
        [action.document.id]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: true,
              writeDocumentId: undefined,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.writeTransaction.done, (state, action) => {
      return immutable(state, {
        [action.result]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              writeDocumentId: action.result,
              entity: action.params.document,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.writeTransaction.failed, (state, action) => {
      return immutable(state, {
        [action.params?.document.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              writeDocumentId: undefined,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.update.started, (state, action) => {
      return immutable(state, {
        [action.before.id]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: true,
              updateDocumentId: undefined,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.update.done, (state, action) => {
      return immutable(state, {
        [action.result]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              updateDocumentId: action.result,
              entity: { ...action.params.before, ...action.params.after },
              error: undefined,
            },
          }),
      });
    })
    .case(actions.update.failed, (state, action) => {
      return immutable(state, {
        [action.params?.before.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              updateDocumentId: undefined,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.updateTransaction.started, (state, action) => {
      return immutable(state, {
        [action.updateParams.before.id]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: true,
              updateDocumentId: undefined,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.updateTransaction.done, (state, action) => {
      return immutable(state, {
        [action.result]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              updateDocumentId: action.result,
              entity: {
                ...action.params.updateParams.before,
                ...action.params.updateParams.after,
              },
              error: undefined,
            },
          }),
      });
    })
    .case(actions.updateTransaction.failed, (state, action) => {
      return immutable(state, {
        [action.params?.updateParams.before.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              updateDocumentId: undefined,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.delete.started, (state, action) => {
      return immutable(state, {
        [action.id]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: true,
              deleteDocumentId: undefined,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.delete.done, (state, action) => {
      return immutable(state, {
        [action.result]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              deleteDocumentId: action.result,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.delete.failed, (state, action) => {
      return immutable(state, {
        [action.params?.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              deleteDocumentId: undefined,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.subscribe.started, (state, action) => {
      return immutable(state, {
        [action.id]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: true,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.subscribe.done, (state, action) => {
      return immutable(state, {
        [action.params?.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              subscribed: true,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.subscribe.failed, (state, action) => {
      return immutable(state, {
        [action.params?.id ??
        throwInTsx('Action Param is undefined')]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              subscribed: false,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.unsubscribe, (state, action) => {
      const key = action ? action.id : undefined;
      if (isNil(key)) {
        //TODO iterated over all keys and update state subscription
        return state;
      }
      return immutable(state, {
        [key]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              loading: false,
              subscribed: false,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.cleanState, (state, action) => {
      return immutable(state, {
        [action]: documentState =>
          immutable(documentState || initialState, {
            $merge: {
              error: undefined,
              writeDocumentId: undefined,
              updateDocumentId: undefined,
              deleteDocumentId: undefined,
            },
          }),
      });
    })
    .case(actions.resetState, (state, action) => {
      const key = action ? action : undefined;
      if (isNil(key)) {
        return state;
      }
      return immutable(state, {
        [key]: documentState =>
          immutable(documentState || initialState, {
            $set: initialState,
          }),
      });
    }) as any) as DocumentStates<DocumentType>;
