import { registerEntity } from '../../../config/firebase/firestore/register-entity';
import {
  salesCollectionName,
  salesCollectionRef,
  salesDocumentRef,
} from '../firebase/sales.store';
import { CollectionReadParams } from '../../../redux/actions/utils/generate-collection-actions';
import { Sales } from '../sales.domain';

export interface SalesCollectionReadParams extends CollectionReadParams<Sales> {
  agenceId: string;
}
export interface SalesCollectionGroupReadParams
  extends CollectionReadParams<Sales> {}

export const SalesRegister = registerEntity<Sales, SalesCollectionReadParams>(
  'sales',
  salesCollectionName,
  ({ agenceId }) => salesCollectionRef(agenceId),
  ['firstName', 'lastName'],
  sales => salesDocumentRef(sales.id, sales.agenceId)
);
