import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Modal } from 'antd';
import { Entity } from '../../domain/utils/entity';
import { DocumentActions } from '../../redux/actions/utils/generate-document-actions';
import { EntityRenderDataType } from '../../domain/types/entity-render-data-type';
import { pushWithQuery, replaceWithQuery } from '../router/push-with-query';

export const useEntityPaths = <DocumentType extends Entity>(
  entityName: string,
  documentActions: DocumentActions<DocumentType>,
  entityRenderer: (entity: DocumentType) => EntityRenderDataType = entity => ({
    title: entityName,
    key: entity.id,
  })
) => {
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();

  const basePage = React.useCallback(() => {
    dispatch(pushWithQuery(url));
  }, [dispatch, url]);

  const entityPage = React.useCallback(
    (itemId?: string | number, doReplace = false, subPath?: string) => {
      if (itemId) {
        const path = `${url}/${entityName}/${itemId}${
          subPath ? `/${subPath}` : ''
        }`;
        dispatch(doReplace ? replaceWithQuery(path) : pushWithQuery(path));
      }
    },
    [dispatch, entityName, url]
  );

  const entityEdit = React.useCallback(
    (itemId?: string) => {
      if (itemId) {
        dispatch(pushWithQuery(`${url}/${entityName}/${itemId}/edit`));
      }
    },
    [dispatch, entityName, url]
  );

  const entityDelete = React.useCallback(
    (item: DocumentType) => {
      Modal.confirm({
        title: 'Confirmation de Suppression',
        content: (
          <span>
            Êtes-vous sur de vouloir supprimer '{entityRenderer(item).title} :{' '}
            {item.id}'?
          </span>
        ),
        okText: 'Supprimer',
        okType: 'danger',
        cancelText: 'Annuler',
        onOk: () => {
          dispatch(documentActions.delete.started(item));
          basePage();
        },
      });
    },
    [basePage, dispatch, documentActions, entityRenderer]
  );
  const documentMatch = useRouteMatch<{ documentId: string }>(
    `${path}/${entityName}/:documentId`
  );

  return {
    basePage,
    entityPage,
    entityEdit,
    entityDelete,
    documentId: documentMatch?.params.documentId,
    path,
  };
};
