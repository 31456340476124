import { storage } from '../../../config/firebase/firebase.config';

export const agencesFolderPath = () => {
  return 'agences';
};

export const agenceFolderPath = (agenceId?: string) => {
  return `${agencesFolderPath()}/${agenceId}`;
};

export const getAgenceBucket = (agenceId: string) => {
  return storage.ref(agenceFolderPath(agenceId));
};

export const getAgenceFilesRef = (agenceId: string) => {
  return getAgenceBucket(agenceId).child('files');
};

export const getAgenceLogoRef = (agenceId: string) => {
  return getAgenceBucket(agenceId).child('logo');
};
