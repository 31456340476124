import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Skeleton } from 'antd';
import { Switch } from 'react-router-dom';
import { ErrorBoundaryRoute } from '../router/error-boundary-route';
import { useSelector } from 'react-redux';
import {
  CollectionActions,
  CollectionReadParams,
} from '../../redux/actions/utils/generate-collection-actions';
import { throwInTsx } from '../utils/throw-in-tsx';
import { CollectionSelectors } from '../../redux/selectors/utils/collection.selector';
import { DocumentActions } from '../../redux/actions/utils/generate-document-actions';
import kebabCase from 'lodash/kebabCase';
import { Modal } from '../utils/modal';
import { Entity } from '../../domain/utils/entity';
import { EntityRenderDataType } from '../../domain/types/entity-render-data-type';
import { emptyArray } from '../../utils/constants';
import { useEntityPaths } from '../utils/use-entity-paths';
import { useParams } from '../router/use-params.hook';
import { useCollectionSubscribedSearch } from '../../hooks/use-collection-subscribed-search.hook';
import { ResponsiveButton } from '../ui/button/responsive-button.component';
import { TitleWithAvatar } from '../ui/avatar/title-with-avatar.component';
import EmptyComponent from '../ui/empty.component';
import './list-display.component.less';
import { useMediaQueries } from '../../style/media-query.context';

export interface ListDisplayProps<
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>
> {
  title?: string;
  readParams: ReadParamType;
  documentActions: DocumentActions<DocumentType>;
  collectionActions: CollectionActions<ReadParamType, DocumentType>;
  collectionStateSelector: CollectionSelectors<DocumentType>;
  entityName: string;
  listRenderData: (document: DocumentType) => EntityRenderDataType;
  renderCreate?: (onDone: (documentId: string) => void) => React.ReactNode;
  renderEdit?: (
    document: DocumentType,
    onDone?: (documentId: string) => void
  ) => React.ReactNode;
  renderDetails?: (
    document?: DocumentType,
    onEdit?: (documentId: string) => void,
    onDelete?: (document: DocumentType) => void
  ) => React.ReactNode;
}

export const ListDisplayComponent = <
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>
>({
  title,
  readParams,
  documentActions,
  collectionActions,
  collectionStateSelector,
  entityName,
  listRenderData,
  renderCreate,
  renderEdit,
  renderDetails,
}: ListDisplayProps<DocumentType, ReadParamType>) => {
  const { mdDown } = useMediaQueries();

  const collectionState = useSelector(
    collectionStateSelector.collectionStateSelector()
  );
  const {
    loading = false,
    collection = emptyArray<DocumentType>(),
  } = collectionState;
  const [items, setItems] = React.useState(collection);
  const {
    basePage,
    entityPage,
    entityEdit,
    entityDelete,
    documentId,
    path,
  } = useEntityPaths(entityName, documentActions);

  const { params, setParams } = useParams();
  const action = params[kebabCase(entityName)];

  const { limit, setLimit, setSearchValue } = useCollectionSubscribedSearch(
    collectionActions,
    readParams
  );

  React.useEffect(() => {
    setItems(collection);
  }, [collection]);

  React.useEffect(() => {
    if (!documentId && items.length > 0 && !mdDown) {
      entityPage(items[0].id, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, documentId, mdDown]);

  return (
    <div
      className={`list-display-component ${documentId ? 'selected-item' : ''}`}
    >
      <div className={'list-sidebar'}>
        <div className={'list-title'}>
          <div className={'title'}>{title}</div>
          {renderCreate && (
            <ResponsiveButton
              responsive
              className={'add-button'}
              icon={<PlusOutlined />}
              onClick={() =>
                setParams({ ...params, [kebabCase(entityName)]: 'new' })
              }
            >
              {entityName}
            </ResponsiveButton>
          )}
        </div>
        <div className={'list-search-bar'}>
          <Input.Search
            size={'small'}
            placeholder={'Recherche ...'}
            onSearch={setSearchValue}
            onChange={e =>
              setItems(
                collection.filter(
                  (v: DocumentType) =>
                    !e.target.value.trim() ||
                    JSON.stringify(v)
                      .toLowerCase()
                      .includes(e.target.value.trim().toLowerCase())
                )
              )
            }
          />
        </div>
        <div className={'menu'}>
          <Menu
            mode="inline"
            selectable
            selectedKeys={documentId ? [documentId] : emptyArray()}
            onClick={clickParam => {
              entityPage(clickParam.key);
            }}
            children={[
              ...items.map(listRenderData).map((data: EntityRenderDataType) => (
                <Menu.Item className={'menu-item'} key={data.key}>
                  <TitleWithAvatar borderRadius={'4px'} {...data} />
                </Menu.Item>
              )),
              ...(loading
                ? [
                    <Menu.Item key={1}>
                      <Skeleton active avatar paragraph={{ rows: 1 }} />
                    </Menu.Item>,
                    <Menu.Item key={2}>
                      <Skeleton active avatar paragraph={{ rows: 1 }} />
                    </Menu.Item>,
                    <Menu.Item key={3}>
                      <Skeleton active avatar paragraph={{ rows: 1 }} />
                    </Menu.Item>,
                  ]
                : emptyArray()),
            ]}
          />
        </div>
        {collection.length === limit && (
          <div className="list-footer">
            <Button
              onClick={() => setLimit(limit + 5)}
              type="ghost"
              loading={loading}
            >
              <small>Charger plus ...</small>
            </Button>
          </div>
        )}
      </div>
      <div className={'list-content'}>
        {action === 'new' && (
          <Modal
            onCancel={() => (documentId ? entityPage(documentId) : basePage())}
          >
            {renderCreate && renderCreate(documentId => entityPage(documentId))}
          </Modal>
        )}
        <Switch>
          {items.length === 0 && !loading ? (
            <EmptyComponent
              buttonMessage={entityName}
              onClick={() =>
                setParams({ ...params, [kebabCase(entityName)]: 'new' })
              }
            />
          ) : (
            documentId && (
              <>
                <ErrorBoundaryRoute
                  path={`${path}/${entityName}/${documentId}/edit`}
                  exact
                  render={() => (
                    <Modal onCancel={() => entityPage(documentId)}>
                      {renderEdit &&
                        items.find(
                          (item: DocumentType) => item.id === documentId
                        ) &&
                        renderEdit(
                          items.find(
                            (item: DocumentType) => item.id === documentId
                          ) || throwInTsx(`cant find ${documentId}!`),
                          entityPage
                        )}
                    </Modal>
                  )}
                />
                <ErrorBoundaryRoute
                  path={`${path}/${entityName}/${documentId}`}
                  render={() =>
                    renderDetails &&
                    renderDetails(
                      items.find(
                        (item: DocumentType) => item.id === documentId
                      ),
                      () => entityEdit(documentId),
                      document => entityDelete(document)
                    )
                  }
                />
              </>
            )
          )}
        </Switch>
      </div>
    </div>
  );
};
