import { combineEpics } from 'redux-observable';
import routerEpic from './router.epic';
import appEpic from './app.epic';
import authEpic from './auth.epic';
import databaseEpic from './database.epic';
import notificationEpic from './notification.epic';
import analyticsEpic from './analytics.epic';
import userEpic from './user.epic';
import { UserRegister } from '../../domain/user/redux/user-register';
import { ProjectRegister } from '../../domain/project/redux/project-register';
import { AgenceRegister } from '../../domain/agence/redux/agence-register';
import { SalesRegister } from '../../domain/sales/redux/sales-register';
import { FileRegister } from '../../domain/file/redux/file-register';

export default combineEpics(
  appEpic,
  authEpic,
  databaseEpic,
  userEpic,
  routerEpic,
  analyticsEpic,
  notificationEpic,
  UserRegister.epics,
  AgenceRegister.epics,
  ProjectRegister.epics,
  SalesRegister.epics,
  FileRegister.epics
);
