import React from 'react';
import { FormEditComponent } from '../form/form-edit.component';
import { ProjectForm } from './project-form';
import { Project } from '../../domain/project/project.domain';
import { ProjectRegister } from '../../domain/project/redux/project-register';

export interface ProjectEditFormProps {
  title?: string;
  project: Project;
  onDone?: (documentId: string) => void;
}

export const ProjectEditFormComponent: React.FC<ProjectEditFormProps> = ({
  title = 'Agence',
  project,
  onDone,
}) => (
  <FormEditComponent
    title={title}
    subTitle={'Éditer'}
    documentStateSelector={ProjectRegister.documentSelectors}
    updateActionCreator={ProjectRegister.documentActions.update}
    cleanActionCreator={ProjectRegister.documentActions.cleanState}
    document={project}
    onDone={onDone}
    children={(project, form) => <ProjectForm project={project} form={form} />}
  />
);
