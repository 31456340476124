import { reducerWithInitialState } from 'typescript-fsa-reducers';
import immutable from 'immutability-helper';
import {
  CollectionActions,
  CollectionReadParams,
} from '../../actions/utils/generate-collection-actions';
import { emptyArray } from '../../../utils/constants';
import { Entity } from '../../../domain/utils/entity';
import isNil from 'lodash/isNil';
import { Properties } from '../../../utils/types';

export const DEFAULT_COLLECTION = 'entities';

export interface CollectionState<DocumentType extends Entity> {
  loading?: boolean;
  error?: any;
  subscribed?: boolean;
  collection: DocumentType[];
}

export interface CollectionStates<DocumentType extends Entity>
  extends Properties<CollectionState<DocumentType>> {}

export const initialCollectionState: CollectionState<any> = {
  loading: true,
  subscribed: false,
  error: undefined,
  collection: emptyArray(),
};

export const generateCollectionReducer = <
  ReadParamType extends CollectionReadParams<DocumentType>,
  DocumentType extends Entity,
  ErrorType extends Error = Error
>(
  actions: CollectionActions<ReadParamType, DocumentType, ErrorType>,
  initialState: CollectionState<DocumentType> = initialCollectionState
) =>
  (reducerWithInitialState<CollectionStates<DocumentType>>({})
    .case(actions.read.started, (state, action) => {
      const key = action.id ?? DEFAULT_COLLECTION;
      return immutable(state, {
        [key]: collectionState =>
          immutable(collectionState || initialState, {
            $merge: {
              loading: true,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.read.done, (state, action) => {
      const key = action.params?.id ?? DEFAULT_COLLECTION;
      return immutable(state, {
        [key]: collectionState =>
          immutable(collectionState || initialState, {
            $merge: {
              loading: false,
              error: undefined,
              collection: action.result.map(doc => doc.data() as DocumentType),
            },
          }),
      });
    })
    .case(actions.read.failed, (state, action) => {
      const key = action.params?.id ?? DEFAULT_COLLECTION;
      return immutable(state, {
        [key]: collectionState =>
          immutable(collectionState || initialState, {
            $merge: {
              loading: false,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.subscribe.started, (state, action) => {
      const key = action.id ?? DEFAULT_COLLECTION;
      return immutable(state, {
        [key]: collectionState =>
          immutable(collectionState || initialState, {
            $merge: {
              loading: true,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.subscribe.done, (state, action) => {
      const key = action.params?.id ?? DEFAULT_COLLECTION;
      return immutable(state, {
        [key]: collectionState =>
          immutable(collectionState || initialState, {
            $merge: {
              loading: false,
              subscribed: true,
              error: undefined,
            },
          }),
      });
    })
    .case(actions.subscribe.failed, (state, action) => {
      const key = action.params?.id ?? DEFAULT_COLLECTION;
      return immutable(state, {
        [key]: collectionState =>
          immutable(collectionState || initialState, {
            $merge: {
              loading: false,
              error: action.error.message,
            },
          }),
      });
    })
    .case(actions.unsubscribe, (state, action) => {
      const key =
        (action ? action.id : DEFAULT_COLLECTION) ?? DEFAULT_COLLECTION;
      //TODO This code doesn't handle unsubscription when action is undefined we should take each key of our state and
      // set subscribed to false
      return immutable(state, {
        [key]: collectionState =>
          immutable(collectionState || initialState, {
            $merge: {
              loading: false,
              error: undefined,
              subscribed: false,
            },
          }),
      });
    })
    .case(actions.resetState, (state, action) => {
      const key = action ? action : undefined;
      if (isNil(key)) {
        return {};
      }
      return immutable(state, {
        [key]: documentState =>
          immutable(documentState || initialState, {
            $set: initialState,
          }),
      });
    }) as any) as CollectionStates<DocumentType>;
