import React from 'react';
import { Modal as AntModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { ModalFuncProps } from 'antd/lib/modal/Modal';

export const confirmModal = ({
  okText = 'Confirmer',
  okType = 'primary',
  cancelText = 'Annuler',
  maskClosable = true,
  ...rest
}: ModalFuncProps) =>
  AntModal.confirm({
    okText,
    okType,
    cancelText,
    maskClosable,
    ...rest,
  });

export const Modal: React.FC<ModalProps> = ({
  visible = true,
  width = '800px',
  footer = null,
  maskClosable = true,
  ...rest
}) => (
  <AntModal
    visible={visible}
    width={width}
    footer={footer}
    maskClosable={maskClosable}
    {...rest}
  />
);
