import { combineLatest, Observable, timer } from 'rxjs';
import { AnyAction } from 'typescript-fsa';
import {
  distinctUntilChanged,
  ignoreElements,
  map,
  mapTo,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { ofAction } from './utils/of-action';
import { combineEpics } from 'redux-observable';
import { ApplicationActions } from '../actions/application.actions';
import { AuthActions } from '../actions/auth.actions';
import isNil from 'lodash/isNil';
import { visibilityChangeObservable } from '../../utils/app-visiblity';

const appVisibilityEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(ApplicationActions.init),
    switchMapTo(
      visibilityChangeObservable.pipe(
        map(visible => ApplicationActions.visibilityChange(visible))
      )
    )
  );

const appInitEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.tokenRefresh),
    // we only take token refresh events if the uid changed (logged in or logged out)
    distinctUntilChanged(
      (action1, action2) => action1.payload?.uid === action2.payload?.uid
    ),
    map(action =>
      isNil(action.payload)
        ? ApplicationActions.ready()
        : ApplicationActions.start(action.payload.uid)
    )
  );

let lastReload = Date.now();
const reloadPwaEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(ApplicationActions.visibilityChange),
    tap(visible => {
      // This makes the app reload after 120 minutes of inactivity
      if (visible.payload && lastReload < Date.now() - 120 * 60 * 1000) {
        if (process.env.REACT_APP_AUTO_RELOAD) {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
      } else {
        lastReload = Date.now();
      }
    }),
    ignoreElements()
  );

const appReadyEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(ApplicationActions.start),
    switchMap(action =>
      combineLatest([
        //TODO remove this when app state is loaded correctly
        timer(0),
        //action$.pipe(ofAction(ProfileRegister.documentActions.read.done)),
      ]).pipe(take(1), mapTo(ApplicationActions.ready(action.payload)))
    )
  );

export default combineEpics(
  appInitEpic,
  appReadyEpic,
  appVisibilityEpic,
  reloadPwaEpic
);
