import {
  DocumentState,
  DocumentStates,
  initialDocumentState,
} from '../../reducers/utils/generate-document-reducer';
import { Entity } from '../../../domain/utils/entity';
import { createSelector, Selector } from 'reselect';
import { DefaultRootState } from 'react-redux';
import firebase from 'firebase/app';
import '../../store';

export const documentStateSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return documentStates[id] || initialDocumentState;
  });

export const entitySelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).entity;
  });

export const documentSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).document;
  });

export const documentIdSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).document?.id;
  });

export const documentExistsSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).document?.exists;
  });

export const loadingSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).loading;
  });

export const subscribedSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).subscribed;
  });

export const writeDocumentIdSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).writeDocumentId;
  });

export const updateDocumentIdSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).updateDocumentId;
  });

export const deleteDocumentIdSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).deleteDocumentId;
  });

export const errorSelector = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
) => (id = '') =>
  createSelector(documentStatesSelector, documentStates => {
    return (documentStates[id] || initialDocumentState).error;
  });

export interface DocumentSelectors<DocumentType extends Entity> {
  documentStateSelector: (
    id?: string
  ) => Selector<DefaultRootState, DocumentState<DocumentType>>;
  entitySelector: (
    id?: string
  ) => Selector<DefaultRootState, DocumentType | undefined>;
  documentSelector: (
    id?: string
  ) => Selector<
    DefaultRootState,
    firebase.firestore.DocumentSnapshot<DocumentType> | undefined
  >;
  documentIdSelector: (
    id?: string
  ) => Selector<DefaultRootState, string | undefined>;
  documentExistsSelector: (
    id?: string
  ) => Selector<DefaultRootState, boolean | undefined>;
  loadingSelector: (
    id?: string
  ) => Selector<DefaultRootState, boolean | undefined>;
  subscribedSelector: (
    id?: string
  ) => Selector<DefaultRootState, boolean | undefined>;
  writeDocumentIdSelector: (
    id?: string
  ) => Selector<DefaultRootState, string | undefined>;
  updateDocumentIdSelector: (
    id?: string
  ) => Selector<DefaultRootState, string | undefined>;
  deleteDocumentIdSelector: (
    id?: string
  ) => Selector<DefaultRootState, string | undefined>;
  errorSelector: (id?: string) => Selector<DefaultRootState, any>;
}

export const generateDocumentSelectors = <DocumentType extends Entity>(
  documentStatesSelector: Selector<
    DefaultRootState,
    DocumentStates<DocumentType>
  >
): DocumentSelectors<DocumentType> => ({
  documentStateSelector: documentStateSelector(documentStatesSelector),
  entitySelector: entitySelector(documentStatesSelector),
  documentSelector: documentSelector(documentStatesSelector),
  documentIdSelector: documentIdSelector(documentStatesSelector),
  documentExistsSelector: documentExistsSelector(documentStatesSelector),
  loadingSelector: loadingSelector(documentStatesSelector),
  subscribedSelector: subscribedSelector(documentStatesSelector),
  writeDocumentIdSelector: writeDocumentIdSelector(documentStatesSelector),
  updateDocumentIdSelector: updateDocumentIdSelector(documentStatesSelector),
  deleteDocumentIdSelector: deleteDocumentIdSelector(documentStatesSelector),
  errorSelector: errorSelector(documentStatesSelector),
});
