import React from 'react';
import { Alert } from 'antd';
import { store } from '../../redux/store';
import { AnalyticsActions } from '../../redux/actions/analytics.actions';

interface IErrorBoundaryProps {
  readonly children: JSX.Element | JSX.Element[];
}

interface IErrorBoundaryState {
  readonly error: any;
  readonly errorInfo: any;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  readonly state: IErrorBoundaryState = {
    error: undefined,
    errorInfo: undefined,
  };

  componentDidCatch(error: any, errorInfo: any) {
    store.dispatch(
      AnalyticsActions.exception({
        description: error,
        stack: errorInfo,
      })
    );
    this.setState({
      error,
      errorInfo,
    });
    setTimeout(
      () => this.setState({ error: undefined, errorInfo: undefined }),
      5000
    );
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      const errorDetails =
        process.env.NODE_ENV === 'development' ? (
          <details>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        ) : undefined;
      return (
        <Alert
          type="error"
          message={<h2>Une erreur s'est produite.</h2>}
          description={<div>{errorDetails}</div>}
        />
      );
    }
    return this.props.children;
  }
}
