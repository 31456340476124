import { firestore } from '../../../config/firebase/firebase.config';
import firebase from 'firebase/app';
import { Sales } from '../sales.domain';
import { agenceCollectionName } from '../../agence/firebase/agence.store';

export const salesCollectionName = 'sales';

export const salesCollectionPath = (agenceId: string) => {
  return `${agenceCollectionName}/${agenceId}/${salesCollectionName}`;
};

export const salesDocumentPath = (salesId: string, agenceId: string) => {
  return `${salesCollectionPath(agenceId)}/${salesId}`;
};

export const salesCollectionRef = (agenceId: string) => {
  return firestore.collection(
    salesCollectionPath(agenceId)
  ) as firebase.firestore.CollectionReference<Sales>;
};

export const salesDocumentRef = (salesId: string, agenceId: string) => {
  return salesCollectionRef(agenceId).doc(salesId);
};
