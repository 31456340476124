import actionCreatorFactory, {
  ActionCreator,
  ActionCreatorFactory,
  AsyncActionCreators,
} from 'typescript-fsa';
import firebase from 'firebase/app';

import { Entity } from '../../../domain/utils/entity';

export interface CollectionReadParams<DocumentType extends Entity> {
  id?: string;
  filters?: Array<{
    field: (keyof DocumentType & string) | string;
    op: firebase.firestore.WhereFilterOp;
    value: any;
  }>;
  orderBy?: {
    field: keyof DocumentType & string;
    direction?: firebase.firestore.OrderByDirection;
  };
  pagination?: {
    limit: number;
    after?: firebase.firestore.DocumentSnapshot<DocumentType>;
  };
}

const ActionTypes = {
  READ: 'READ',
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  RESET_STATE: 'RESET_STATE',
};

export interface CollectionActions<
  ReadParamType extends CollectionReadParams<DocumentType>,
  DocumentType extends Entity,
  ErrorType extends Error = Error
> {
  read: AsyncActionCreators<
    ReadParamType,
    firebase.firestore.DocumentSnapshot<DocumentType>[],
    ErrorType
  >;
  subscribe: AsyncActionCreators<ReadParamType, void, ErrorType>;
  unsubscribe: ActionCreator<ReadParamType | void>;
  resetState: ActionCreator<string | void>;
  __factory__: ActionCreatorFactory;
}

export const generateCollectionActions = <
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>,
  ErrorType extends Error = Error
>(
  prefix: string
): CollectionActions<ReadParamType, DocumentType, ErrorType> => {
  const factory = actionCreatorFactory(prefix);
  return {
    read: factory.async(ActionTypes.READ),
    subscribe: factory.async(ActionTypes.SUBSCRIBE),
    unsubscribe: factory(ActionTypes.UNSUBSCRIBE),
    resetState: factory(ActionTypes.RESET_STATE),
    __factory__: factory as ActionCreatorFactory,
  };
};
