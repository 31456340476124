import { firestore } from '../../../config/firebase/firebase.config';
import firebase from 'firebase/app';
import { User } from '../user.domain';

export const userCollectionName = 'users';

export const userCollectionPath = () => {
  return userCollectionName;
};

export const userDocumentPath = (userId?: string) => {
  return `${userCollectionPath()}/${userId}`;
};

export const userCollectionRef = () => {
  return firestore.collection(
    userCollectionPath()
  ) as firebase.firestore.CollectionReference<User>;
};

export const userDocumentRef = (userId: string) => {
  return userCollectionRef().doc(userId);
};
