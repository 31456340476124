import { Observable } from 'rxjs';
import { AnyAction } from 'typescript-fsa';
import { filter, map, mapTo } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { ofActionType } from './utils/of-action';
import { AnalyticsActions } from '../actions/analytics.actions';
import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
import { ApplicationActions } from '../actions/application.actions';

const routerAnalyticsEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofActionType<LocationChangePayload>(LOCATION_CHANGE),
    map(action =>
      AnalyticsActions.pageView({
        page_path: action.payload.location.pathname,
        page_location: action.payload.location.pathname,
        ...action.payload.location,
      })
    )
  );

const appInitEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofActionType<LocationChangePayload>(LOCATION_CHANGE),
    filter(action => action.payload.isFirstRendering),
    mapTo(ApplicationActions.init())
  );

export default combineEpics(appInitEpic, routerAnalyticsEpic);
