import { from, Observable, of } from 'rxjs';
import { AnyAction } from 'typescript-fsa';
import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import firebase from 'firebase/app';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import { DatabaseActions } from '../actions/database.actions';
import { ofAction } from './utils/of-action';
import { NotificationActions } from '../actions/notification.actions';
import { AnalyticsActions } from '../actions/analytics.actions';
import { database } from '../../config/firebase/firebase.config';

const refReadEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(DatabaseActions.read.started),
    mergeMap(action =>
      from(database.refFromURL(action.payload).once('value')).pipe(
        map(result =>
          DatabaseActions.read.done({
            params: action.payload,
            result,
          })
        ),
        catchError(error =>
          of(
            DatabaseActions.read.failed({
              params: action.payload,
              error,
            }),
            NotificationActions.error({
              message: 'La lecture a échoué !',
            }),
            AnalyticsActions.exception({
              description: error.message,
              type: error.name,
              stack: error.stack,
              document: `${action.payload.toString()}`,
            })
          )
        )
      )
    )
  );

const refSubscribeEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(DatabaseActions.subscribe.started),
    mergeMap(action =>
      new Observable<firebase.database.DataSnapshot>(observer => {
        const callback = database.refFromURL(action.payload).on(
          'value',
          snapshot => observer.next(snapshot),
          (error: Error) => observer.error(error)
        );
        return () => {
          database.refFromURL(action.payload).off('value', callback);
          observer.complete();
        };
      }).pipe(
        map(result =>
          DatabaseActions.read.done({
            params: action.payload,
            result,
          })
        ),
        startWith(
          DatabaseActions.subscribe.done({
            params: action.payload,
          })
        ),
        catchError(error =>
          of(
            DatabaseActions.read.failed({
              params: action.payload,
              error,
            }),
            DatabaseActions.subscribe.failed({
              params: action.payload,
              error,
            }),
            AnalyticsActions.exception({
              description: error.message,
              type: error.name,
              stack: error.stack,
              document: `${action.payload.toString()}`,
            })
          )
        ),
        takeUntil(
          action$.pipe(
            ofAction(
              DatabaseActions.subscribe.started,
              DatabaseActions.unsubscribe
            ),
            filter(
              unsubscribeAction =>
                isNil(unsubscribeAction.payload) ||
                isEqual(
                  unsubscribeAction.payload.toString(),
                  action.payload.toString()
                )
            )
          )
        )
      )
    )
  );

export default combineEpics(refReadEpic, refSubscribeEpic);
