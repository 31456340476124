const arrayInstance = Object.freeze([]);
const objectInstance = Object.freeze({});

export const emptyArray = <T>(): T[] => {
  // Ignoring the `readonly` part
  // @ts-ignore
  return arrayInstance;
};

export const emptyObject = <V, K extends string | number | symbol = string>(): {
  [key in K]: V;
} => {
  // Ignoring the `readonly` part
  // @ts-ignore
  return objectInstance;
};

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

// Auth Claims
export const ROLE = 'role';
