import { registerEntity } from '../../../config/firebase/firestore/register-entity';
import {
  fileCollectionName,
  fileCollectionRef,
  fileDocumentRef,
} from '../firebase/file.store';
import { CollectionReadParams } from '../../../redux/actions/utils/generate-collection-actions';
import { File } from '../file.domain';

export interface FileCollectionReadParams extends CollectionReadParams<File> {
  agenceId: string;
  projectId: string;
}
export interface FileCollectionGroupReadParams
  extends CollectionReadParams<File> {}

export const FileRegister = registerEntity<File, FileCollectionReadParams>(
  'file',
  fileCollectionName,
  ({ agenceId, projectId }) => fileCollectionRef(agenceId, projectId),
  ['fileInfo.name'],
  file => fileDocumentRef(file.id, file.agenceId, file.projectId)
);
