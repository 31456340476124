import React from 'react';
import { Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeFirestoreFieldsRecursively,
  removeUndefinedRecursively,
} from '../utils/form.helpers';
import { FormContainerComponent } from './form-container.component';
import { Entity } from '../../domain/utils/entity';
import { FormInstance } from 'antd/es/form';
import { ActionCreator, AsyncActionCreators } from 'typescript-fsa';
import { ResponsiveButton } from '../ui/button/responsive-button.component';
import { DocumentSelectors } from '../../redux/selectors/utils/document.selector';
import { UpdateParams } from '../../redux/actions/utils/generate-document-actions';

export interface FormEditProps<DocumentType extends Entity> {
  title: string;
  subTitle: string;
  documentStateSelector: DocumentSelectors<DocumentType>;
  updateActionCreator: AsyncActionCreators<
    UpdateParams<DocumentType>,
    string,
    Error
  >;
  cleanActionCreator: ActionCreator<string>;
  document: DocumentType;
  onDone?: (documentId: string, document?: DocumentType) => void;
  children:
    | React.ReactNode
    | ((document: DocumentType, form: FormInstance) => React.ReactNode);
  onBack?: () => void;
  renderFooter?: (form: FormInstance) => React.ReactNode;
}

export const FormEditComponent = <DocumentType extends Entity>({
  title,
  subTitle,
  documentStateSelector,
  updateActionCreator,
  cleanActionCreator,
  document,
  onDone = () => {},
  children,
  onBack,
  renderFooter,
}: FormEditProps<DocumentType>) => {
  const dispatch = useDispatch();
  const documentState = useSelector(
    documentStateSelector.documentStateSelector(document.id)
  );

  const [formEdited, setFormEdited] = React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    return () => {
      if (cleanActionCreator) dispatch(cleanActionCreator(document.id));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    form.setFieldsValue(document);
  }, [form, document]);

  React.useEffect(() => {
    if (
      documentState.updateDocumentId &&
      documentState.updateDocumentId === document.id
    ) {
      onDone(
        documentState.updateDocumentId,
        removeFirestoreFieldsRecursively(documentState.entity)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentState.loading,
    documentState.updateDocumentId,
    documentState.writeDocumentId,
  ]);

  const onFinish = (documentForm: any) => {
    setFormEdited(false);
    documentForm = removeUndefinedRecursively(documentForm, {
      deleteField: true,
      deleteEmpty: true,
    });
    dispatch(
      updateActionCreator.started({
        before: document,
        after: documentForm,
      })
    );
  };

  const onFieldsChange = () => {
    setFormEdited(true);
  };

  return (
    <FormContainerComponent
      title={title}
      subTitle={subTitle}
      loading={documentState.loading}
      footer={
        renderFooter ? (
          renderFooter(form)
        ) : (
          <ResponsiveButton
            icon={<EditOutlined />}
            danger
            disabled={!formEdited}
            onClick={form.submit}
          >
            Éditer
          </ResponsiveButton>
        )
      }
      blockNavigation={formEdited}
      onBack={onBack}
    >
      <Form
        colon={true}
        form={form}
        name={title}
        onFinish={onFinish}
        layout={'vertical'}
        onFieldsChange={onFieldsChange}
        initialValues={document}
      >
        {typeof children === 'function' ? children(document, form) : children}
      </Form>
    </FormContainerComponent>
  );
};
