import React from 'react';
import { Button, Form, Spin } from 'antd';
import { AuthActions } from '../../redux/actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { authSelector } from '../../redux/selectors/auth.selector';
import './login-form.component.less';
import { EmailField } from '../form/field/email-field.component';

export const ForgotPasswordForm: React.FC = () => {
  const dispatch = useDispatch();
  const authState = useSelector(authSelector);

  React.useEffect(() => {
    if (authState.user) {
      dispatch(push('/'));
    }
  }, [authState.user, dispatch]);

  const onFinish = (values: any) => {
    dispatch(
      AuthActions.forgotPassword.started({
        email: values.email,
      })
    );
  };

  return (
    <Spin spinning={authState.loading}>
      <Form
        className={'login-form'}
        name="forgotPasswordForm"
        onFinish={onFinish}
      >
        <EmailField
          autoFocus
          required
          noLabel
          validateStatus={authState.error ? 'error' : undefined}
          help={
            authState.error
              ? 'Un problème est survenu: Veuillez réessayer !'
              : undefined
          }
        />

        <Form.Item className={'login-actions'}>
          <Button className={'login-button'} type="primary" htmlType="submit">
            Valider
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
