import { firestore } from '../../../config/firebase/firebase.config';
import firebase from 'firebase/app';
import { File } from '../file.domain';
import { projectDocumentPath } from '../../project/firebase/project.store';

export const fileCollectionName = 'file';

export const fileCollectionPath = (agenceId: string, projectId: string) => {
  return `${projectDocumentPath(projectId, agenceId)}/${fileCollectionName}`;
};

export const fileDocumentPath = (
  fileId: string,
  agenceId: string,
  projectId: string
) => {
  return `${fileCollectionPath(agenceId, projectId)}/${fileId}`;
};

export const fileCollectionRef = (agenceId: string, projectId: string) => {
  return firestore.collection(
    fileCollectionPath(agenceId, projectId)
  ) as firebase.firestore.CollectionReference<File>;
};

export const fileDocumentRef = (
  fileId: string,
  agenceId: string,
  projectId: string
) => {
  return fileCollectionRef(agenceId, projectId).doc(fileId);
};
