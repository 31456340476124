import isNil from 'lodash/isNil';
import split from 'lodash/split';
import uniq from 'lodash/uniq';
import {
  lowerCaseRemoveSpecialChars,
  removeWhiteSpaces,
} from '../../../helpers/strings';

export const generateSearchArray = <DocumentType>(
  searchFields: Array<(keyof DocumentType & string) | string>,
  document: DocumentType,
  minLength = 3,
  maxLength = 10
): string[] => {
  return uniq(
    searchFields
      .map(field => field.split('.'))
      .map(fields => {
        let value: any = document;
        fields.forEach(field => {
          if (value) {
            value = value[field];
          }
        });
        return value;
      })
      .flatMap(value => value)
      .filter(value => !isNil(value))
      .filter(value => typeof value === 'string' || value instanceof String)
      // @ts-ignore
      .map(lowerCaseRemoveSpecialChars)
      .map(removeWhiteSpaces)
      .flatMap(value => [...split(value, /[\s-_]/), value])
      .flatMap(word => {
        let result = [];
        for (
          let i = word.length > minLength ? minLength : word.length;
          i <= maxLength;
          i++
        ) {
          result.push(word.substr(0, i).trim());
        }
        return result;
      })
  );
};
