import { history } from './router';
import { connectRouter } from 'connected-react-router';
import rootReducer from '../reducers/root.reducer';

export type RootReducerType = typeof reducers;

declare module 'react-redux' {
  export interface DefaultRootState extends RootReducerType {}
}

export const reducers = {
  router: connectRouter(history),
  ...rootReducer,
};
