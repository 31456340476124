import { registerEntity } from '../../../config/firebase/firestore/register-entity';
import {
  agenceCollectionName,
  agenceCollectionRef,
  agenceDocumentRef,
} from '../firebase/agence.store';
import { CollectionReadParams } from '../../../redux/actions/utils/generate-collection-actions';
import { Agence } from '../agence.domain';

export interface AgenceCollectionReadParams
  extends CollectionReadParams<Agence> {}

export const AgenceRegister = registerEntity<
  Agence,
  AgenceCollectionReadParams
>(
  'agence',
  agenceCollectionName,
  () => agenceCollectionRef(),
  ['code'],
  agence => {
    return agenceDocumentRef(agence.id);
  }
);
