import { DefaultRootState } from 'react-redux';
import { RouterLocation, RouterState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { LocationState } from 'history';

export const routerStateSelector = (state: DefaultRootState) =>
  (state.router as unknown) as RouterState;

export const locationSelector = createSelector(
  routerStateSelector,
  (state: RouterState) => state?.location
);

export const routerActionSelector = createSelector(
  routerStateSelector,
  (state: RouterState) => state?.action
);

export const locationPathnameSelector = createSelector(
  locationSelector,
  (state: RouterLocation<LocationState>) => state?.pathname
);
