import React from 'react';
import { AvatarComponent } from './avatar.component';
import './title-with-avatar.component.less';

export interface TitleWithAvatarProps {
  avatarSrc?: string;
  avatarOnly?: boolean;
  title: string;
  subTitle?: string;
  size?: string | number;
  borderRadius?: any;
}

export const TitleWithAvatar: React.FC<TitleWithAvatarProps> = ({
  avatarSrc,
  avatarOnly = false,
  title,
  subTitle,
  size = '28px',
  borderRadius,
}) => (
  <div className={'item-avatar'}>
    <AvatarComponent
      borderRadius={borderRadius}
      src={avatarSrc}
      name={title}
      size={size}
    />
    {title && !avatarOnly && (
      <div className={'content'}>
        <span className={'title'}>{title}</span>
        {subTitle && <span className={'sub-title'}>{subTitle}</span>}
      </div>
    )}
  </div>
);
