import React from 'react';
import { Entity } from '../../domain/utils/entity';
import { FormInstance } from 'antd/es/form';
import { ActionCreator, AsyncActionCreators } from 'typescript-fsa';
import { DocumentSelectors } from '../../redux/selectors/utils/document.selector';
import { FormTransactionCreateComponent } from './form-transaction-create.component';

export interface FormCreateProps<DocumentType extends Entity> {
  title: string;
  subTitle: string;
  documentStateSelector: DocumentSelectors<DocumentType>;
  writeActionCreator: AsyncActionCreators<DocumentType, string, Error>;
  cleanActionCreator: ActionCreator<string>;
  documentId?: string;
  initialValues: Partial<DocumentType>;
  onDone?: (newDocumentId: string) => void;
  children:
    | React.ReactNode
    | ((newId: string, form?: FormInstance) => React.ReactNode);
  onBack?: () => void;
  renderFooter?: (form: FormInstance) => React.ReactNode;
}

export const FormCreateComponent = <DocumentType extends Entity>({
  title,
  subTitle,
  documentStateSelector,
  writeActionCreator,
  cleanActionCreator,
  documentId,
  initialValues,
  onDone,
  children,
  onBack,
  renderFooter,
}: FormCreateProps<DocumentType>) => {
  return (
    <FormTransactionCreateComponent
      title={title}
      subTitle={subTitle}
      documentStateSelector={documentStateSelector}
      writeActionCreator={writeActionCreator}
      cleanActionCreator={cleanActionCreator}
      documentId={documentId}
      initialValues={initialValues}
      onDone={onDone}
      children={children}
      onBack={onBack}
      renderFooter={renderFooter}
    />
  );
};
