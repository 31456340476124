import { firestore } from '../../../config/firebase/firebase.config';
import firebase from 'firebase/app';
import { Project } from '../project.domain';
import { agenceCollectionName } from '../../agence/firebase/agence.store';

export const projectCollectionName = 'projects';

export const projectCollectionPath = (agenceId: string) => {
  return `${agenceCollectionName}/${agenceId}/${projectCollectionName}`;
};

export const projectDocumentPath = (projectId: string, agenceId: string) => {
  return `${projectCollectionPath(agenceId)}/${projectId}`;
};

export const projectCollectionRef = (agenceId: string) => {
  return firestore.collection(
    projectCollectionPath(agenceId)
  ) as firebase.firestore.CollectionReference<Project>;
};

export const projectDocumentRef = (projectId: string, agenceId: string) => {
  return projectCollectionRef(agenceId).doc(projectId);
};
