import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { useMediaQueries } from '../../../style/media-query.context';

export interface ResponsiveButtonProps {
  responsive?: boolean;
}

export const ResponsiveButton: React.FC<
  ResponsiveButtonProps & ButtonProps
> = ({ responsive = false, children, icon, ...rest }) => {
  const { mdDown } = useMediaQueries();

  return (
    <Button type={'primary'} icon={icon} {...rest}>
      {(!responsive || !mdDown || !icon) && children}
    </Button>
  );
};
