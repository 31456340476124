import React from 'react';
import { PageHeader, Spin } from 'antd';
import { Prompt } from 'react-router-dom';
import { useMediaQueries } from '../../style/media-query.context';

export interface FormContainerProps {
  loading?: boolean;
  title: string;
  subTitle?: string;
  footer?: React.ReactNode;
  blockNavigation?: boolean;
  onBack?: () => void;
}

export const FormContainerComponent: React.FC<FormContainerProps> = ({
  children,
  loading = false,
  title,
  subTitle = '',
  footer,
  blockNavigation = false,
  onBack,
}) => {
  const { mdDown } = useMediaQueries();

  return (
    <Spin spinning={loading}>
      <PageHeader
        onBack={onBack}
        title={title}
        subTitle={!mdDown ? subTitle : undefined}
        footer={footer}
        style={{ padding: 0 }}
      >
        <Prompt
          when={blockNavigation}
          message="Vous avez des modifications en cours, abandonner ?"
        />
        {children}
      </PageHeader>
    </Spin>
  );
};
