import React from 'react';
import {
  ProjectCollectionGroupReadParams,
  ProjectRegister,
} from '../../domain/project/redux/project-register';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ListDisplayComponent } from '../list/list-display.component';
import { projectRenderData } from '../../domain/project/project.domain';
import { ProjectCreateFormComponent } from './project-create-form.component';
import { ProjectEditFormComponent } from './project-edit-form.component';
import { ProjectPageComponent } from './project-page.component';
import { useRouteMatch } from 'react-router-dom';

interface ProjectListProps {
  userId?: string;
}

export const ProjectList: React.FC<ProjectListProps> = ({ userId }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  React.useEffect(() => {
    dispatch(ProjectRegister.collectionGroupActions.subscribe.started({}));
  }, [dispatch]);

  const readParams = React.useMemo<ProjectCollectionGroupReadParams>(
    () => ({}),
    []
  );

  return (
    <ListDisplayComponent
      title={'Projets'}
      readParams={readParams}
      documentActions={ProjectRegister.documentActions}
      collectionActions={ProjectRegister.collectionGroupActions}
      collectionStateSelector={ProjectRegister.collectionGroupSelectors}
      entityName={'project'}
      listRenderData={projectRenderData}
      renderCreate={onDone => <ProjectCreateFormComponent onDone={onDone} />}
      renderEdit={(project, onDone) =>
        onDone && <ProjectEditFormComponent project={project} onDone={onDone} />
      }
      renderDetails={(project, onEdit, onDelete) =>
        project &&
        onEdit && (
          <ProjectPageComponent
            project={project}
            onEdit={onEdit}
            onDelete={onDelete}
            onBack={() => dispatch(push(url))}
          />
        )
      }
    />
  );
};
