import React from 'react';
import { Button, Form, Input, Spin, Alert } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AuthActions } from '../../redux/actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import './login-form.component.less';
import { authSelector } from '../../redux/selectors/auth.selector';
import { EmailField } from '../form/field/email-field.component';
import { auth } from '../../config/firebase/firebase.config';

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState(
    window.localStorage.getItem('email')
  );
  const authState = useSelector(authSelector);
  const location = useLocation() as any;
  const from = location.state?.from;
  const pathname = location.pathname;
  const emailLogin = process.env.REACT_APP_EMAIL_LOGIN;

  React.useEffect(() => {
    if (authState.user) {
      dispatch(replace(from ?? '/'));
    }
  }, [authState.user, dispatch, from]);

  React.useEffect(() => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      let emailInput = email;
      if (!emailInput) {
        emailInput = window.prompt(
          'Veuillez renseigner votre email de connection'
        );
        setEmail(emailInput);
      }
      if (!emailInput) {
        return;
      }
      dispatch(AuthActions.emailLogin.started({ email: emailInput }));
    }
  }, [dispatch, email, location.search, pathname]);

  const onFinish = (values: any) => {
    emailLogin
      ? dispatch(
          AuthActions.emailLoginSend.started({
            email: values.email,
          })
        )
      : dispatch(
          AuthActions.login.started({
            email: values.email,
            password: values.password,
          })
        );
  };

  return (
    <>
      {emailLogin && authState.loading && (
        <Alert
          type={'success'}
          message={'Connexion par Email'}
          description={
            'Un email contenant un lien de connexion vous a été envoyé, merci de cliquer dessus !'
          }
        />
      )}
      <br />
      <Spin spinning={authState.loading}>
        <Form className={'login-form'} name="loginForm" onFinish={onFinish}>
          <EmailField defaultValue={email ?? ''} autoFocus noLabel required />
          {!emailLogin && (
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir un mot de passe!',
                },
              ]}
              validateStatus={authState.error ? 'error' : undefined}
              help={
                authState.error
                  ? "Un problème est survenu: Nom d'utilisateur ou mot de passe incorrect !"
                  : undefined
              }
            >
              <Input.Password placeholder="Mot de passe" />
            </Form.Item>
          )}

          <Form.Item className={'login-actions'}>
            <Button className={'login-button'} type="primary" htmlType="submit">
              Se Connecter
            </Button>
            {!emailLogin && (
              <div className={'login-helpers'}>
                <Link to="forgot-password">Mot de passe oublié ?</Link>
              </div>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
