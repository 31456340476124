import actionCreatorFactory from 'typescript-fsa';

const factory = actionCreatorFactory('APP');

export const ApplicationActionTypes = {
  INIT: 'INIT',
  START: 'START',
  READY: 'READY',
  DESTROY: 'DESTROY',
  VISIBILITY_CHANGE: 'VISIBILITY_CHANGE',
};

export const ApplicationActions = {
  init: factory(ApplicationActionTypes.INIT),
  start: factory<string>(ApplicationActionTypes.START),
  ready: factory<string | void>(ApplicationActionTypes.READY),
  destroy: factory(ApplicationActionTypes.DESTROY),
  visibilityChange: factory<boolean>(ApplicationActionTypes.VISIBILITY_CHANGE),
};
