import React from 'react';
import { textToColor } from './color-utils';
import './avatar.component.less';

export interface AvatarProps {
  name: string;
  src?: string;
  color?: string;
  size?: any;
  style?: React.CSSProperties;
  className?: string;
  borderRadius?: any;
}

export const initials = (str: string) => {
  if (!str) {
    return '';
  }
  const split = str.split(' ');
  return (
    split[0].charAt(0) + (split.length > 1 ? split[1].charAt(0) : str.charAt(1))
  );
};

const setPx = (size: string | number) =>
  size.toString().indexOf('px') === -1 ? size + 'px' : size;

export const AvatarComponent = (props: AvatarProps) => {
  const {
    src,
    name,
    color,
    size = 72,
    className,
    style = {},
    borderRadius = '50%',
  } = props;
  const abbr = initials(name).toUpperCase();
  const imageStyle: React.CSSProperties = { display: 'block' };
  style.borderRadius = borderRadius;
  const innerStyle: React.CSSProperties = {
    lineHeight: setPx(size),
    textAlign: 'center',
    borderRadius,
    fontSize:
      size > 50 ? '170%' : size > 40 ? '120%' : size > 20 ? '90%' : '70%',
    fontWeight: 'bold',
  };
  if (size) {
    innerStyle.width = innerStyle.maxWidth = size;
    innerStyle.height = innerStyle.maxHeight = size;
  }
  const classes = [className, 'avatar'];
  let inner;
  if (src) {
    inner = (
      <img className="avatar-img" style={imageStyle} src={src} alt={name} />
    );
  } else {
    innerStyle.backgroundColor = color ? color : textToColor(name);
    inner = <span>{abbr}</span>;
  }
  return (
    <div aria-label={name} className={classes.join(' ')} style={style}>
      <div className="avatar-inner" style={innerStyle}>
        {inner}
      </div>
    </div>
  );
};
