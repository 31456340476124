import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { File } from '../../domain/file/file.domain';

export const FileColumns: ColumnsType<File> = [
  {
    title: 'Nom',
    key: 'id',
    dataIndex: 'fileInfo',
    render: fileInfo => `${fileInfo?.name ?? 'Sans Nom'}`,
  },
  {
    title: 'Date Création',
    render: file => <span>Download</span>,
  },
];
