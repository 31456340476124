import actionCreatorFactory from 'typescript-fsa';
import { Properties } from '../../utils/types';

const factory = actionCreatorFactory('ANALYTICS');

export const AnalyticsActionTypes = {
  SET_USER_ID: 'SET_USER_ID',
  SET_USER_PROPERTIES: 'SET_USER_PROPERTIES',
  LOG_EVENT: 'LOG_EVENT',
  EXCEPTION: 'EXCEPTION',
  PAGE_VIEW: 'PAGE_VIEW',
};

export interface AnalyticsParam {
  eventName: string;
  eventParams: Properties;
}

export interface ExceptionParam extends Properties {
  description?: string;
  fatal?: boolean;
}

export interface PageViewParam extends Properties {
  page_title?: string;
  page_location?: string;
  page_path?: string;
}
export const AnalyticsActions = {
  setUserId: factory<string>(AnalyticsActionTypes.SET_USER_ID),
  setUserProperties: factory<Properties>(
    AnalyticsActionTypes.SET_USER_PROPERTIES
  ),
  logEvent: factory<AnalyticsParam>(AnalyticsActionTypes.LOG_EVENT),
  exception: factory<ExceptionParam>(AnalyticsActionTypes.EXCEPTION),
  pageView: factory<PageViewParam>(AnalyticsActionTypes.PAGE_VIEW),
};
