import React from 'react';
import './select-display.component.less';
import { Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  CollectionActions,
  CollectionReadParams,
} from '../../redux/actions/utils/generate-collection-actions';
import { DocumentActions } from '../../redux/actions/utils/generate-document-actions';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import {
  EditOutlined,
  PlusOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import { CollectionSelectors } from '../../redux/selectors/utils/collection.selector';
import { DocumentSelectors } from '../../redux/selectors/utils/document.selector';
import RcSelect from 'rc-select';
import { Modal } from '../utils/modal';
import { Entity } from '../../domain/utils/entity';
import { emptyArray } from '../../utils/constants';
import EmptyComponent from '../ui/empty.component';
import { TitleWithAvatar } from '../ui/avatar/title-with-avatar.component';
import { useCollectionSubscribedSearch } from '../../hooks/use-collection-subscribed-search.hook';
import { EntityRenderDataType } from '../../domain/types/entity-render-data-type';

export interface SelectDisplayProps<
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>
> {
  title: string;
  isEntity?: boolean;
  fields?: Array<keyof DocumentType>;
  value?: string | DocumentType;
  entityValue?: DocumentType;
  onChange?: (value?: string | DocumentType) => void;
  numberOfOptions?: number;
  readParams: ReadParamType;
  collectionActions: CollectionActions<ReadParamType, DocumentType>;
  documentActions: DocumentActions<DocumentType>;
  documentStateSelector: DocumentSelectors<DocumentType>;
  collectionStateSelector: CollectionSelectors<DocumentType>;
  optionRenderData: (document: DocumentType) => EntityRenderDataType;
  emptyButton?: string;
  renderCreate?: (onDone: (documentId: string) => void) => React.ReactNode;
  renderEdit?: (
    document: DocumentType,
    onDone: (documentId: string, document?: DocumentType) => void
  ) => React.ReactNode;
  autoFocus: boolean;
}

export const SelectDisplayComponent = <
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>
>({
  title,
  isEntity = false,
  fields,
  value,
  entityValue,
  onChange = () => {},
  numberOfOptions = 3,
  readParams,
  collectionActions,
  documentActions,
  collectionStateSelector,
  documentStateSelector,
  optionRenderData,
  emptyButton = 'Créer',
  renderCreate,
  renderEdit,
  autoFocus,
}: SelectDisplayProps<DocumentType, ReadParamType>) => {
  const selectRef = React.useRef<RcSelect<string>>(null);
  const dispatch = useDispatch();
  const collectionState = useSelector(
    collectionStateSelector.collectionStateSelector('select')
  );
  const documentState = useSelector(
    documentStateSelector.documentStateSelector(
      typeof value === 'string' ? value : value?.id
    )
  );
  const {
    collection = emptyArray<DocumentType>(),
    loading: collectionLoading,
    subscribed,
  } = collectionState;
  const {
    entity,
    loading: documentLoading,
    subscribed: documentSubscribed,
  } = documentState;
  const loading = collectionLoading || documentLoading;
  const [id, setId] = React.useState<string>();
  const [document, setDocument] = React.useState<DocumentType>();
  const [items, setItems] = React.useState<DocumentType[]>(collection);
  const [showCreate, setShowCreate] = React.useState(false);
  const [itemToEdit, setItemToEdit] = React.useState<DocumentType>();
  const documentReadParams = (readParams as any) as DocumentType;

  const collectionReadParams = React.useMemo(
    () => ({
      id: 'select',
      ...readParams,
    }),
    [readParams]
  );

  const {
    setSearchValue,
    setStartSubscribe,
    limit,
    setLimit,
  } = useCollectionSubscribedSearch(
    collectionActions,
    collectionReadParams,
    numberOfOptions,
    false
  );

  React.useEffect(() => {
    if (isEmpty(id)) {
      return;
    }
    const selected = find(items, item => item.id === id);
    if (isEntity && isNil(document)) {
      if (selected) {
        onChange(fields ? (pick(selected, fields) as any) : selected);
      } else if (entity?.id === id) {
        onChange(fields ? (pick(entity, fields) as any) : entity);
      }
    } else if (!isEntity && entity?.id === id) {
      setDocument(entity);
    } else if (!isEntity && selected?.id === id) {
      setDocument(selected);
    }
  }, [document, entity, isEntity, id, items, onChange, fields]);

  React.useEffect(() => {
    setItems(collection);
  }, [collection]);

  React.useEffect(() => {
    if (typeof value === 'string') {
      setId(value);
      if (value === entityValue?.id) {
        setDocument(entityValue);
      } else {
        setDocument(undefined);
      }
    } else {
      setId(value?.id);
      setDocument(value);
    }
  }, [value, entityValue]);

  React.useEffect(() => {
    if (
      !isNil(document) ||
      isEmpty(id) ||
      isNil(documentReadParams) ||
      items.findIndex(item => item.id === id) > -1 ||
      documentSubscribed
    ) {
      return;
    }
    dispatch(documentActions.subscribe.started({ ...documentReadParams, id }));
    return () => {
      dispatch(documentActions.unsubscribe({ ...documentReadParams, id }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentActions, documentReadParams, id, items, dispatch]);

  const onSelectChange = (id: string) => {
    if (isEntity) {
      const entity = find(items, item => item.id === id);
      onChange(fields ? (pick(entity, fields) as any) : entity);
    } else {
      onChange(id);
    }
  };

  return (
    <>
      <Select
        allowClear
        ref={selectRef}
        autoFocus={autoFocus}
        placeholder={`${title}: Recherche ...`}
        loading={loading || collectionLoading}
        showSearch
        autoClearSearchValue={false}
        onSearch={searchValue => {
          setSearchValue(searchValue);
          setStartSubscribe(true);
        }}
        style={{ width: '100%' }}
        listHeight={350}
        optionLabelProp="label"
        filterOption={false}
        onDropdownVisibleChange={visible => {
          if (!subscribed && visible) {
            setStartSubscribe(true);
          }
        }}
        onChange={onSelectChange}
        value={document?.id}
        notFoundContent={
          <EmptyComponent
            buttonMessage={emptyButton}
            onClick={() => setShowCreate(true)}
          />
        }
      >
        {(document && find(items, item => item.id === id) === undefined
          ? [document, ...items]
          : items
        )
          .map(optionRenderData)
          .map(data => (
            <Select.Option
              className={'select-option-with-avatar'}
              value={data.key}
              key={data.key}
              label={data.title}
            >
              <TitleWithAvatar
                avatarSrc={data.avatarSrc}
                title={data.title}
                subTitle={data.subTitle}
                size={'20px'}
              />
              {renderEdit && (
                <Button
                  type={'text'}
                  icon={<EditOutlined />}
                  onClick={() => {
                    selectRef.current?.blur();
                    setItemToEdit(items.find(item => item.id === data.key));
                  }}
                />
              )}
            </Select.Option>
          ))}
        {limit <= items.length && (
          <Select.Option
            style={{ cursor: 'pointer' }}
            value={''}
            key={'more'}
            label={''}
            disabled
          >
            <Button
              size={'small'}
              style={{ width: '100%' }}
              icon={<ArrowDownOutlined />}
              type={'text'}
              onClick={e => {
                setLimit(limit + 3);
                e.stopPropagation();
              }}
            />
          </Select.Option>
        )}
        {renderCreate && (
          <Select.Option
            style={{ cursor: 'pointer' }}
            value={''}
            key={'create'}
            label={''}
            disabled
          >
            <Button
              size={'small'}
              style={{ width: '100%' }}
              icon={<PlusOutlined />}
              type={'dashed'}
              onClick={() => {
                selectRef.current?.blur();
                setShowCreate(true);
              }}
            >
              {emptyButton}
            </Button>
          </Select.Option>
        )}
      </Select>
      <Modal
        visible={showCreate}
        onCancel={() => setShowCreate(false)}
        destroyOnClose
      >
        {renderCreate &&
          renderCreate(documentId => {
            onChange(documentId);
            setShowCreate(false);
          })}
      </Modal>
      <Modal
        visible={!!itemToEdit}
        onCancel={() => setItemToEdit(undefined)}
        destroyOnClose
      >
        {renderEdit &&
          itemToEdit &&
          renderEdit(itemToEdit, (documentId, document) => {
            if (isEntity) {
              onChange(fields ? (pick(document, fields) as any) : document);
            } else {
              onChange(documentId);
            }
            setItemToEdit(undefined);
          })}
      </Modal>
    </>
  );
};
