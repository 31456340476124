import React from 'react';

interface ConditionalWrapperProps {
  wrap: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  wrap,
  wrapper,
  children,
}) => {
  return <>{wrap ? wrapper(children) : children}</>;
};
