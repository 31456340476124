import React from 'react';
import { FormInstance } from 'antd/es/form';
import { Form } from 'antd';
import { File } from '../../domain/file/file.domain';
import { DocumentUploadComponent } from '../form/components/document-upload.component';
import { getFileFilesRef } from '../../domain/file/firebase/file.storage';

interface FileFormProps {
  newFileId?: string;
  agenceId?: string;
  projectId?: string;
  file?: File;
  form?: FormInstance;
}

export const FileForm: React.FC<FileFormProps> = ({
  agenceId,
  projectId,
  file,
  newFileId,
}) => {
  return (
    <>
      <Form.Item
        name={'fileInfo'}
        label={'Document'}
        rules={[
          {
            required: true,
            message: 'Veillez télécharger un document !',
          },
        ]}
      >
        <DocumentUploadComponent
          fileRef={getFileFilesRef(
            file?.id ?? newFileId ?? 'nofileid',
            file?.agenceId ?? agenceId ?? 'noagence',
            file?.projectId ?? projectId ?? 'noproject'
          )}
        />
      </Form.Item>
    </>
  );
};
