import { Middleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import { history } from './router';

export const epicMiddleware = createEpicMiddleware();

const middlewares: Middleware[] = [epicMiddleware];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  middlewares.push(createLogger({ collapsed: true }));
}
middlewares.push(routerMiddleware(history));

export { middlewares };
