import React, { useContext } from 'react';
import Media from 'react-media';
import isEqual from 'lodash/isEqual';

const xsEnd = 480;
const smStart = 481;
const smEnd = 576;
const mdStart = 577;
const mdEnd = 768;
const lgStart = 767;
const lgEnd = 992;
const xlStart = 993;

const MediaQueryContext = React.createContext<MediaQueryType>({});
const MediaQueries = {
  xs: `(max-width: ${xsEnd}px)`,
  sm: `(min-width: ${smStart}px) and (max-width: ${smEnd}px)`,
  md: `(min-width: ${mdStart}px) and (max-width: ${mdEnd}px)`,
  lg: `(min-width: ${lgStart}px) and (max-width: ${lgEnd}px)`,
  xl: `(min-width: ${xlStart}px)`,

  xsDown: `(max-width: ${xsEnd}px)`,
  smDown: `(max-width: ${smEnd}px)`,
  mdDown: `(max-width: ${mdEnd}px)`,
  lgDown: `(max-width: ${lgEnd}px)`,
  xlDown: `(max-width: ${xlStart}px)`,

  xsUp: `(min-width: 0px)`,
  smUp: `(min-width: ${smStart}px)`,
  mdUp: `(min-width: ${mdStart}px)`,
  lgUp: `(min-width: ${lgStart}px)`,
  xlUp: `(min-width: ${xlStart}px)`,
};
export interface MediaQueryType {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;

  xsDown?: boolean;
  smDown?: boolean;
  mdDown?: boolean;
  lgDown?: boolean;
  xlDown?: boolean;

  xsUp?: boolean;
  smUp?: boolean;
  mdUp?: boolean;
  lgUp?: boolean;
  xlUp?: boolean;
}

const MediaQueryContextComponent: React.FC<{
  matches: MediaQueryType;
}> = React.memo(
  ({ children, matches }) => {
    return (
      <MediaQueryContext.Provider value={matches}>
        {children}
      </MediaQueryContext.Provider>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.matches, nextProps.matches) ||
    isEqual(nextProps.matches, {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      xsDown: false,
      smDown: false,
      mdDown: false,
      lgDown: false,
      xlDown: false,
      xsUp: false,
      smUp: false,
      mdUp: false,
      lgUp: false,
      xlUp: false,
    }) ||
    (nextProps.matches.sm ? 1 : 0) +
      (nextProps.matches.md ? 1 : 0) +
      (nextProps.matches.lg ? 1 : 0) +
      (nextProps.matches.xl ? 1 : 0) >
      1
);
export const MediaQueryProvider: React.FC = ({ children }) => {
  return (
    <Media queries={MediaQueries}>
      {matches => (
        <MediaQueryContextComponent matches={matches} children={children} />
      )}
    </Media>
  );
};

export const useMediaQueries = () => useContext(MediaQueryContext);
