import {
  CollectionActions,
  CollectionReadParams,
  generateCollectionActions,
} from '../../../redux/actions/utils/generate-collection-actions';
import { Entity } from '../../../domain/utils/entity';
import { combineEpics, Epic } from 'redux-observable';
import {
  DocumentActions,
  generateDocumentActions,
} from '../../../redux/actions/utils/generate-document-actions';
import { generateCollectionEpic } from '../../../redux/epics/utils/generate-collection-epic';
import firebase from 'firebase/app';
import { generateDocumentEpic } from '../../../redux/epics/utils/generate-document-epic';
import { emptyArray } from '../../../utils/constants';
import {
  CollectionStates,
  generateCollectionReducer,
} from '../../../redux/reducers/utils/generate-collection-reducer';
import {
  DocumentState,
  DocumentStates,
  generateDocumentReducer,
} from '../../../redux/reducers/utils/generate-document-reducer';
import {
  DocumentSelectors,
  generateDocumentSelectors,
} from '../../../redux/selectors/utils/document.selector';
import {
  CollectionSelectors,
  generateCollectionSelectors,
} from '../../../redux/selectors/utils/collection.selector';
import { DefaultRootState } from 'react-redux';
import memoize from 'lodash/memoize';
import { firestore } from '../firebase.config';
import { Properties } from '../../../utils/types';

export interface RegisteredEntity<
  DocumentType extends Entity,
  CollectionReadParamType extends CollectionReadParams<DocumentType>,
  ErrorType extends Error = Error
> {
  collectionActions: CollectionActions<
    CollectionReadParamType,
    DocumentType,
    ErrorType
  >;
  collectionGroupActions: CollectionActions<
    CollectionReadParams<DocumentType>,
    DocumentType,
    ErrorType
  >;
  collectionSelectors: CollectionSelectors<DocumentType>;
  collectionGroupSelectors: CollectionSelectors<DocumentType>;
  documentActions: DocumentActions<DocumentType, ErrorType>;
  documentSelectors: DocumentSelectors<DocumentType>;
  reducers: Properties;
  epics: Epic;
}
export const documentKey = (storeKey: string) =>
  `${storeKey}_document`.toUpperCase();
export const collectionKey = (storeKey: string) =>
  `${storeKey}_collection`.toUpperCase();
export const collectionGroupKey = (storeKey: string) =>
  `${storeKey}_group`.toUpperCase();

export const registerEntity = <
  DocumentType extends Entity,
  CollectionReadParamType extends CollectionReadParams<DocumentType>,
  ErrorType extends Error = Error
>(
  storeKey: string,
  entityName: string,
  getCollection: (
    params: CollectionReadParamType
  ) => firebase.firestore.Query<DocumentType>,
  searchFields: Array<(keyof DocumentType & string) | string> = emptyArray(),
  getDocument?: (
    document: DocumentType
  ) => firebase.firestore.DocumentReference<DocumentType>,
  initialState?: DocumentState<DocumentType>
): RegisteredEntity<DocumentType, CollectionReadParamType, ErrorType> => {
  const collectionActions = generateCollectionActions<
    DocumentType,
    CollectionReadParamType,
    ErrorType
  >(collectionKey(storeKey));
  const collectionGroupActions = generateCollectionActions<
    DocumentType,
    CollectionReadParams<DocumentType>,
    ErrorType
  >(collectionGroupKey(storeKey));
  const documentActions = generateDocumentActions<DocumentType, ErrorType>(
    documentKey(storeKey)
  );
  const documentStatesSelector = (
    state: DefaultRootState
  ): DocumentStates<DocumentType> =>
    // @ts-ignore
    state[documentKey(storeKey)];
  const collectionStatesSelector = (
    state: DefaultRootState
  ): CollectionStates<DocumentType> =>
    // @ts-ignore
    state[collectionKey(storeKey)];
  const collectionGroupStatesSelector = (
    state: DefaultRootState
  ): CollectionStates<DocumentType> =>
    // @ts-ignore
    state[collectionGroupKey(storeKey)];

  return {
    collectionActions,
    collectionGroupActions,
    collectionSelectors: generateCollectionSelectors(collectionStatesSelector),
    collectionGroupSelectors: generateCollectionSelectors(
      collectionGroupStatesSelector
    ),
    documentActions,
    documentSelectors: generateDocumentSelectors(documentStatesSelector),
    reducers: {
      [documentKey(storeKey)]: generateDocumentReducer(
        documentActions,
        initialState
      ),
      [collectionKey(storeKey)]: generateCollectionReducer(collectionActions),
      [collectionGroupKey(storeKey)]: generateCollectionReducer(
        collectionGroupActions
      ),
    },
    epics: combineEpics(
      generateCollectionEpic(
        memoize(
          () =>
            firestore.collectionGroup(
              entityName
            ) as firebase.firestore.Query<DocumentType>
        ),
        collectionGroupActions
      ),
      generateCollectionEpic(memoize(getCollection), collectionActions),
      getDocument &&
        generateDocumentEpic(
          memoize(getDocument, (doc: DocumentType) => doc.id),
          documentActions,
          searchFields
        )
    ),
  };
};
