import React from 'react';
import { Header } from './header/header.component';
import { Footer } from './footer/footer.component';
import { Content } from './content/content.component';
import { isMobile } from 'react-device-detect';
import './layout.component.less';
import { useSelector } from 'react-redux';
import { authSelector } from '../../redux/selectors/auth.selector';
import { useMediaQueries } from '../../style/media-query.context';

export const Layout: React.FC = props => {
  const { xsDown } = useMediaQueries();
  const authState = useSelector(authSelector);
  return (
    <div className={`layout-component`}>
      {authState.user && <Header />}
      <Content>{props.children}</Content>
      {authState.user && !isMobile && !xsDown && <Footer />}
    </div>
  );
};
