import { message } from 'antd';
import { emptyArray } from '../utils/constants';
import { Address } from '../domain/types/address';

export const searchAddress = async (query: string) => {
  if (!query || query.length < 4) {
    return emptyArray();
  }
  try {
    const response = await fetch(
      `https://api-adresse.data.gouv.fr/search?q=${query}`
    );
    const data = await response.json();
    const features = data.features;
    if (features) {
      return features.map((feature: any) => {
        const properties = feature.properties;
        const address: Address = {};
        address.addressLine1 = properties.name;
        address.city = properties.city;
        address.postalCode = properties.postcode;
        address.country = 'France';
        return address;
      });
    }
  } catch (error) {
    message.error("Erreur lors de la recherche d'adresse");
    return emptyArray();
  }
};
