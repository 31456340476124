import immutable from 'immutability-helper';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AuthActions } from '../actions/auth.actions';
import firebase from 'firebase/app';

export interface AuthState {
  loading?: boolean;
  user?: firebase.User | null;
  idToken?: firebase.auth.IdTokenResult | null;
  error?: string;
}

export const initialState: AuthState = {
  loading: false,
};

const authReducer = {
  auth: reducerWithInitialState<AuthState>(initialState)
    .case(AuthActions.tokenRefresh, (state, user) => {
      return immutable(state, {
        user: { $set: user },
      });
    })
    .case(AuthActions.idTokenRefresh.started, state => {
      return immutable(state, {
        $unset: ['error'],
      });
    })
    .case(AuthActions.idTokenRefresh.done, (state, { result }) => {
      return immutable(state, {
        idToken: { $set: result },
        $unset: ['error'],
      });
    })
    .case(AuthActions.idTokenRefresh.failed, state => {
      return immutable(state, {
        loading: { $set: false },
        $unset: ['idToken', 'error'],
      });
    })
    .case(AuthActions.login.started, state => {
      return immutable(state, {
        loading: { $set: true },
        $unset: ['error'],
      });
    })
    .case(AuthActions.login.done, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        user: { $set: action.result },
        $unset: ['error'],
      });
    })
    .case(AuthActions.login.failed, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        error: { $set: action.error.message },
      });
    })
    .case(AuthActions.emailLoginSend.started, state => {
      return immutable(state, {
        loading: { $set: true },
        $unset: ['error'],
      });
    })
    .case(AuthActions.emailLoginSend.done, (state, action) => {
      return immutable(state, {
        $unset: ['error'],
      });
    })
    .case(AuthActions.emailLoginSend.failed, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        error: { $set: action.error.message },
      });
    })
    .case(AuthActions.emailLogin.started, state => {
      return immutable(state, {
        loading: { $set: true },
        $unset: ['error'],
      });
    })
    .case(AuthActions.emailLogin.done, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        user: { $set: action.result },
        $unset: ['error'],
      });
    })
    .case(AuthActions.emailLogin.failed, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        error: { $set: action.error.message },
      });
    })
    .case(AuthActions.forgotPassword.started, state => {
      return immutable(state, {
        loading: { $set: true },
        $unset: ['error'],
      });
    })
    .case(AuthActions.forgotPassword.done, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        $unset: ['error'],
      });
    })
    .case(AuthActions.forgotPassword.failed, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        error: { $set: action.error.message },
      });
    })
    .case(AuthActions.logout.started, (state, action) => {
      return immutable(state, {
        loading: { $set: true },
        $unset: ['error'],
      });
    })
    .case(AuthActions.logout.done, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        $unset: ['error', 'idToken', 'user'],
      });
    })
    .case(AuthActions.logout.failed, (state, action) => {
      return immutable(state, {
        loading: { $set: false },
        error: { $set: action.error.message },
      });
    }),
};

export default authReducer;
