import { Alert, Card, Divider } from 'antd';
import React from 'react';
import { LoginForm } from './login-form.component';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ForgotPasswordForm } from './forgot-password-form.component';

import { ReactComponent as LogoSvg } from '../../images/logo.svg';
import './auth.component.less';

export const Auth: React.FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  return (
    <div className={'auth'}>
      <Card className={'auth-container'}>
        <div className="logo">
          <LogoSvg />
        </div>
        <Divider style={{ color: 'gray', margin: '2rem 0' }}>
          {pathname.indexOf('login') !== -1
            ? 'Connexion'
            : 'Récupération de Compte'}
        </Divider>
        <Switch>
          <Route path={`${path}/login`} component={LoginForm} />
          <Route
            path={`${path}/forgot-password/done`}
            render={() => (
              <Alert
                style={{ marginBottom: '1rem' }}
                message="Si un compte existe avec cet email, des instructions de réinitialisation vous ont été envoyées !"
                type="success"
              />
            )}
          />
          <Route
            path={`${path}/forgot-password`}
            component={ForgotPasswordForm}
          />
        </Switch>
      </Card>
    </div>
  );
};
