import React from 'react';
import {
  AgenceCollectionReadParams,
  AgenceRegister,
} from '../../domain/agence/redux/agence-register';
import { SelectDisplayComponent } from '../select/select-display.component';
import { Agence, agenceRenderData } from '../../domain/agence/agence.domain';
import { AgenceCreateFormComponent } from './agence-create-form.component';
import { AgenceEditFormComponent } from './agence-edit-form.component';

export interface AgenceSelectProps {
  isEntity?: boolean;
  fields?: Array<keyof Agence>;
  value?: string | Agence;
  entityValue?: Agence;
  onChange?: (value?: string | Agence) => void;
  autoFocus?: boolean;
}

export const AgenceSelectComponent: React.FC<AgenceSelectProps> = ({
  isEntity = false,
  fields,
  value,
  entityValue,
  onChange,
  autoFocus = false,
}) => {
  const readParams = React.useMemo<AgenceCollectionReadParams>(() => ({}), []);

  return (
    <SelectDisplayComponent
      autoFocus={autoFocus}
      title={'Agence'}
      value={value}
      entityValue={entityValue}
      onChange={onChange}
      isEntity={isEntity}
      fields={fields}
      readParams={readParams}
      collectionActions={AgenceRegister.collectionActions}
      documentActions={AgenceRegister.documentActions}
      collectionStateSelector={AgenceRegister.collectionSelectors}
      documentStateSelector={AgenceRegister.documentSelectors}
      optionRenderData={agenceRenderData}
      renderCreate={onDone => <AgenceCreateFormComponent onDone={onDone} />}
      renderEdit={(agence, onDone) => (
        <AgenceEditFormComponent agence={agence} onDone={onDone} />
      )}
    />
  );
};
