import React from 'react';
import './footer.component.less';

export const Footer: React.FC = () => (
  <div className={'footer-component'}>
    {process.env.REACT_APP_COMPANY_NAME ??
      'Set Company REACT_APP_COMPANY_NAME env var'}{' '}
    <strong>©{new Date().getFullYear()}</strong>
  </div>
);
