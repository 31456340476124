import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Sales } from '../../domain/sales/sales.domain';

export const SalesColumns: ColumnsType<Sales> = [
  {
    title: 'Nom',
    render: (_: string, sales) => `${sales.firstName} ${sales.lastName}`,
  },
  {
    title: 'N° Téléphone',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    responsive: ['md'],
    render: (phoneNumber: string) => (
      <a onClick={e => e.stopPropagation()} href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </a>
    ),
  },
];
