import React from 'react';
import { Form } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import {
  removeLeadingZeros,
  removeWhiteSpaces,
} from '../../../helpers/strings';
import { NumberInput } from '../components/number-input.component';

interface PhoneNumberFieldProps {
  name?: string;
  label?: string;
  required?: boolean;
}
export const phoneNumberFormatter = (phoneNumber?: string) => {
  // noinspection SuspiciousTypeOfGuard
  if (phoneNumber && typeof phoneNumber === 'string') {
    const matches = phoneNumber.match(/(^\d)|(\d{1,2})/g);
    return matches ? matches.join(' ') : '';
  }
  return '-';
};

export const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  name = 'phoneNumber',
  label = 'Numéro de Téléphone',
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message: `Veillez saisir le numéro de téléphone !`,
        },
        {
          len: 12,
          message: `Le numéro de téléphone n'est pas valide !`,
        },
      ]}
      normalize={value => {
        return removeWhiteSpaces(value)?.substring(0, 12);
      }}
    >
      <NumberInput
        formatter={value => `${value ?? ''}`.slice(3)}
        parser={value => (value ? `+33${removeLeadingZeros(value)}` : value)}
        placeholder={'6 12 23 34 45'}
        prefix={'+33 (0) '}
        icon={<PhoneOutlined />}
        isDecimal={false}
        inputMode="tel"
      />
    </Form.Item>
  );
};
