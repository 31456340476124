import { Observable } from 'rxjs';
import { AnyAction } from 'typescript-fsa';
import { ignoreElements, tap } from 'rxjs/operators';
import { ofAction } from './utils/of-action';
import { combineEpics, StateObservable } from 'redux-observable';
import { DefaultRootState } from 'react-redux';
import { AuthState } from '../reducers/auth.reducer';
import { UserRegister } from '../../domain/user/redux/user-register';

const createUserEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<DefaultRootState>
) =>
  action$.pipe(
    ofAction(UserRegister.documentActions.write.done),
    tap(action => {
      (state$.value.auth as AuthState).user?.updateProfile({
        displayName: `${
          action.payload.params.firstName
        } ${action.payload.params.lastName?.substr(0, 1)}.`,
        photoURL: action.payload.params.profilePic,
      });
    }),
    ignoreElements()
  );

const updateUserEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<DefaultRootState>
) =>
  action$.pipe(
    ofAction(UserRegister.documentActions.update.done),
    tap(action => {
      const profile = {} as any;
      if (action.payload.params.after.firstName) {
        profile.displayName = `${
          action.payload.params.after.firstName
        } ${action.payload.params.after.lastName?.substr(0, 1)}.`;
      }
      if (action.payload.params.after.profilePic) {
        profile.photoURL = action.payload.params.after.profilePic;
      }
      (state$.value.auth as AuthState).user?.updateProfile(profile);
    }),
    ignoreElements()
  );

export default combineEpics(createUserEpic, updateUserEpic);
