import { useSelector } from 'react-redux';
import { emailSelector } from '../redux/selectors/auth.selector';

export const useRole = () => {
  const email = useSelector(emailSelector);
  let role = 'user';
  if (email?.includes('acerpa')) {
    role = 'admin';
  }
  return { role };
};
