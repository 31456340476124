import React from 'react';
import { Grid, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelector,
  displayNameSelector,
  emailSelector,
  photoURLSelector,
} from '../../../redux/selectors/auth.selector';
import { AuthActions } from '../../../redux/actions/auth.actions';
import {
  LoginOutlined,
  PoweroffOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { TitleWithAvatar } from '../../ui/avatar/title-with-avatar.component';
import { push } from 'connected-react-router';

export const RightMenu = () => {
  const { md } = Grid.useBreakpoint();
  const dispatch = useDispatch();

  const displayName = useSelector(displayNameSelector);
  const email = useSelector(emailSelector);
  const photoUrl = useSelector(photoURLSelector);
  const authState = useSelector(authSelector);

  const logoutHandler = React.useCallback(
    () => dispatch(AuthActions.logout.started()),
    [dispatch]
  );

  return (
    <Menu mode={md ? 'horizontal' : 'inline'} selectable={false}>
      {authState.user ? (
        <Menu.SubMenu
          className={'auth-menu'}
          icon={
            <TitleWithAvatar
              avatarSrc={photoUrl ?? undefined}
              title={displayName || 'Mon Compte'}
              subTitle={email ?? undefined}
            />
          }
        >
          <Menu.Item
            icon={<ToolOutlined />}
            onClick={() => dispatch(push('/settings'))}
          >
            Paramètres
          </Menu.Item>
          <Menu.Divider />
          {authState.user ? (
            <Menu.Item onClick={logoutHandler}>
              <PoweroffOutlined />
              Se Déconnecter
            </Menu.Item>
          ) : (
            <Menu.Item onClick={() => dispatch(push('/auth/login'))}>
              <LoginOutlined />
              Se Connecter
            </Menu.Item>
          )}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key="login">
          <Link to="/auth/login">Se Connecter</Link>
        </Menu.Item>
      )}
    </Menu>
  );
};
