import React from 'react';
import { Project } from '../../domain/project/project.domain';
import {
  SalesCollectionReadParams,
  SalesRegister,
} from '../../domain/sales/redux/sales-register';
import { TableDisplayComponent } from '../table/table-display.component';
import { SalesColumns } from '../sales/sales-columns';

export interface ProjectSalesTableProps {
  project: Project;
}

export const ProjectSalesTable: React.FC<ProjectSalesTableProps> = ({
  project,
}) => {
  const readParams = React.useMemo<SalesCollectionReadParams>(() => {
    return {
      agenceId: project.agenceId,
      filters: [
        { field: 'id', op: 'in', value: project.salesList ?? ['none'] },
      ],
    };
  }, [project.agenceId, project.salesList]);

  return (
    <TableDisplayComponent
      columns={SalesColumns}
      readParams={readParams}
      documentActions={SalesRegister.documentActions}
      collectionActions={SalesRegister.collectionActions}
      collectionStateSelector={SalesRegister.collectionSelectors}
      entityName={'sales'}
      collectionName={'sales'}
    />
  );
};
