import immutable from 'immutability-helper';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ApplicationActions } from '../actions/application.actions';

export interface AppState {
  loading?: boolean;
  visible?: boolean;
  error?: string;
}

export const initialState: AppState = {
  loading: true,
  visible: true,
};

const appReducer = {
  app: reducerWithInitialState<AppState>(initialState)
    .case(ApplicationActions.init, state => {
      return immutable(state, {
        loading: { $set: true },
      });
    })
    .case(ApplicationActions.start, state => {
      return immutable(state, {
        loading: { $set: true },
      });
    })
    .case(ApplicationActions.ready, state => {
      return immutable(state, {
        loading: { $set: false },
      });
    })
    .case(ApplicationActions.visibilityChange, (state, visible) => {
      return immutable(state, {
        visible: { $set: visible },
      });
    }),
};

export default appReducer;
