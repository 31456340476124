import React from 'react';
import { AutoComplete, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import { ShopTwoTone } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { searchCompanyByName } from '../../../helpers/siren';
import { emptyArray } from '../../../utils/constants';

const renderOption = (company: any, index: number) => ({
  company,
  value: `${index}`,
  key: `${index}`,
  label: (
    <div>
      <ShopTwoTone />
      <small style={{ margin: '0 1rem' }}>
        {company.name}, {company.siren}, {company.address?.city ?? ''}
      </small>
    </div>
  ),
});

export interface CompanyNameFieldProps {
  name?: string;
  required?: boolean;
  label?: string;
  search?: boolean;
  form?: FormInstance;
  autoFocus?: boolean;
}

export const CompanyNameField: React.FC<CompanyNameFieldProps> = ({
  name = 'name',
  required = false,
  label = 'Raison Sociale',
  search = true,
  form,
  autoFocus = false,
}) => {
  const [companyList, setCompanyList] = React.useState<any[]>(emptyArray());

  const onSearch = debounce(
    async value => setCompanyList(await searchCompanyByName(value)),
    500
  );

  const onSelect = (value: string, option: any) => {
    form?.setFieldsValue(option.company);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      required={required}
      rules={[
        {
          required,
          message: "La 'Raison Sociale' est requise !",
        },
      ]}
    >
      {search ? (
        <AutoComplete
          autoFocus
          options={companyList.map(renderOption)}
          onSelect={onSelect}
          onSearch={onSearch}
        >
          <Input.Search inputMode={'search'} placeholder="Saisir un nom ..." />
        </AutoComplete>
      ) : (
        <Input autoFocus={autoFocus} placeholder="Saisir un nom ..." />
      )}
    </Form.Item>
  );
};
