import { Observable } from 'rxjs';
import { AnyAction } from 'typescript-fsa';
import { ignoreElements, map, tap } from 'rxjs/operators';
import { combineEpics, StateObservable } from 'redux-observable';
import { analytics } from '../../config/firebase/firebase.config';
import { ofAction } from './utils/of-action';
import { AnalyticsActions } from '../actions/analytics.actions';
import { DefaultRootState } from 'react-redux';
import { ApplicationActions } from '../actions/application.actions';

const setUserIdEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<DefaultRootState>
) =>
  action$.pipe(
    ofAction(ApplicationActions.ready),
    map(action => action.payload),
    map(userId => AnalyticsActions.setUserId(userId || ''))
  );

const setUserId = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AnalyticsActions.setUserId),
    tap(action => analytics.setUserId(action.payload)),
    ignoreElements()
  );

const setUserProperties = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AnalyticsActions.setUserProperties),
    tap(action => analytics.setUserProperties(action.payload)),
    ignoreElements()
  );

const logEventEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AnalyticsActions.logEvent),
    tap(action =>
      analytics.logEvent(action.payload.eventName, action.payload.eventParams)
    ),
    ignoreElements()
  );

const exceptionEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AnalyticsActions.exception),
    tap(action => {
      analytics.logEvent('exception', action.payload);
      if (process.env.NODE_ENV === 'development') {
        console.error(action.payload);
      }
    }),
    ignoreElements()
  );

const pageViewEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AnalyticsActions.exception),
    tap(action => analytics.logEvent('page_view', action.payload)),
    ignoreElements()
  );

export default combineEpics(
  setUserIdEpic,
  setUserId,
  setUserProperties,
  logEventEpic,
  exceptionEpic,
  pageViewEpic
);
