import React from 'react';
import { Project } from '../../domain/project/project.domain';
import {
  FileCollectionReadParams,
  FileRegister,
} from '../../domain/file/redux/file-register';
import { TableDisplayComponent } from '../table/table-display.component';
import { FileColumns } from '../file/file-columns';
import { fileCollectionName } from '../../domain/file/firebase/file.store';
import { FileCreateFormComponent } from './file-create-form.component';
import { FileEditFormComponent } from './file-edit-form.component';

export interface FilesTableProps {
  project: Project;
}

export const FilesTable: React.FC<FilesTableProps> = ({ project }) => {
  const readParams = React.useMemo<FileCollectionReadParams>(() => {
    return {
      agenceId: project.agenceId,
      projectId: project.id,
    };
  }, [project.agenceId, project.id]);

  return (
    <TableDisplayComponent
      title={'Fichiers'}
      entityName={'Fichier'}
      collectionName={fileCollectionName}
      columns={FileColumns}
      readParams={readParams}
      documentActions={FileRegister.documentActions}
      collectionActions={FileRegister.collectionActions}
      collectionStateSelector={FileRegister.collectionSelectors}
      renderCreate={onDone => (
        <FileCreateFormComponent
          projectId={project.id}
          agenceId={project.agenceId}
          onDone={onDone}
        />
      )}
      renderEdit={(file, onDone) => (
        <FileEditFormComponent file={file} onDone={onDone} />
      )}
    />
  );
};
