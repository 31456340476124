import { storage } from '../../../config/firebase/firebase.config';
import { fileCollectionPath } from './file.store';

export const fileFolderPath = (
  fileId: string,
  agenceId: string,
  projectId: string
) => {
  return `${fileCollectionPath(agenceId, projectId)}/${fileId}`;
};

export const getFileBucket = (
  fileId: string,
  agenceId: string,
  projectId: string
) => {
  return storage.ref(fileFolderPath(fileId, agenceId, projectId));
};

export const getFileFilesRef = (
  fileId: string,
  agenceId: string,
  projectId: string
) => {
  return getFileBucket(fileId, agenceId, projectId).child('files');
};
