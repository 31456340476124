import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  userRoleSelector,
  userIdSelector,
} from '../../redux/selectors/auth.selector';
import { ErrorBoundaryRoute } from './error-boundary-route';
import { UserRole } from '../../domain/enum/user-role';
import { emptyArray } from '../../utils/constants';
import { AccessDenied } from '../error/access-denied.component';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  roles?: UserRole[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  roles = emptyArray(),
  children,
  ...rest
}) => {
  const userId = useSelector(userIdSelector);
  const role = useSelector(userRoleSelector);
  return (
    <ErrorBoundaryRoute
      {...rest}
      render={({ location }) =>
        userId ? (
          roles?.length === 0 || roles?.includes(role) ? (
            children
          ) : (
            <AccessDenied />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
