import {
  applyMiddleware,
  combineReducers,
  createStore,
  ReducersMapObject,
} from 'redux';
import { composeEnhancers } from './config/dev-tools';
import { reducers } from './config/reducers';
import { epicMiddleware, middlewares } from './config/middlewares';
import rootEpic from './epics/root.epic';

const configStore = (initialState = {}) => {
  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(...middlewares)
  )(createStore);

  const storeWithMiddleware = createStoreWithMiddleware(
    combineReducers((reducers as any) as ReducersMapObject),
    initialState
  );

  // We should run epic middleware after store creation.
  epicMiddleware.run(rootEpic);

  return storeWithMiddleware;
};

export const store = configStore();
