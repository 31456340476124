import { from, Observable, of } from 'rxjs';
import { AnyAction } from 'typescript-fsa';
import { ofAction } from './of-action';
import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import firebase from 'firebase/app';
import {
  CollectionActions,
  CollectionReadParams,
} from '../../actions/utils/generate-collection-actions';
import { AnalyticsActions } from '../../actions/analytics.actions';
import { NotificationActions } from '../../actions/notification.actions';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import { Entity } from '../../../domain/utils/entity';

export const addFiltersPaginationOrderBy = <DocumentType extends Entity>(
  query: firebase.firestore.Query<DocumentType>,
  { filters, pagination, orderBy }: CollectionReadParams<DocumentType>
): firebase.firestore.Query<DocumentType> => {
  if (filters) {
    filters.forEach(({ field, op, value }) => {
      query = query.where(field, op, value);
    });
  }
  if (pagination) {
    const { limit, after } = pagination;
    query = query.limit(limit);
    if (after) {
      query = query.startAfter(after);
    }
  }
  if (orderBy) {
    const { field, direction = 'asc' } = orderBy;
    query = query.orderBy(field, direction);
  }
  return query;
};

export const generateCollectionEpic = <
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>,
  ErrorType extends Error = Error
>(
  getCollection: (
    params: ReadParamType
  ) => firebase.firestore.Query<DocumentType>,
  collectionActionsCreator: CollectionActions<
    ReadParamType,
    DocumentType,
    ErrorType
  >
) => {
  const collectionReadEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(collectionActionsCreator.read.started),
      mergeMap(action => {
        const collectionReference = getCollection(action.payload);
        let query = addFiltersPaginationOrderBy(
          collectionReference,
          action.payload
        );
        return from(query.get()).pipe(
          map(result =>
            collectionActionsCreator.read.done({
              params: action.payload,
              result: result.docs,
            })
          ),
          catchError(error =>
            of(
              collectionActionsCreator.read.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La lecture a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
              })
            )
          )
        );
      })
    );

  const collectionSubscribeEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(collectionActionsCreator.subscribe.started),
      switchMap(action => {
        const collectionReference = getCollection(action.payload);
        let query = addFiltersPaginationOrderBy(
          collectionReference,
          action.payload
        );
        return new Observable<firebase.firestore.QuerySnapshot<DocumentType>>(
          observer => {
            const unsubscribe = query.onSnapshot(
              snapshot => observer.next(snapshot),
              error => observer.error(error),
              () => observer.complete()
            );
            return () => {
              unsubscribe();
            };
          }
        ).pipe(
          map(result =>
            collectionActionsCreator.read.done({
              params: action.payload,
              result: result.docs,
            })
          ),
          startWith(
            collectionActionsCreator.subscribe.done({
              params: action.payload,
            })
          ),
          catchError(error =>
            of(
              collectionActionsCreator.read.failed({
                params: action.payload,
                error,
              }),
              collectionActionsCreator.subscribe.failed({
                params: action.payload,
                error,
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
              })
            )
          ),
          takeUntil(
            action$.pipe(
              ofAction(collectionActionsCreator.unsubscribe),
              filter(
                unsubscribeAction =>
                  isNil(unsubscribeAction.payload) ||
                  isEqual(unsubscribeAction.payload, action.payload)
              )
            )
          )
        );
      })
    );

  return combineEpics(collectionReadEpic, collectionSubscribeEpic);
};
