import { from, Observable, of } from 'rxjs';

import { AuthActions } from '../actions/auth.actions';
import { NotificationActions } from '../actions/notification.actions';
import { AnyAction } from 'typescript-fsa';
import {
  catchError,
  delay,
  map,
  mergeMap,
  mergeMapTo,
  startWith,
  switchMap,
  switchMapTo,
} from 'rxjs/operators';
import { ofAction } from './utils/of-action';
import { combineEpics } from 'redux-observable';
import { auth } from '../../config/firebase/firebase.config';
import { push } from 'connected-react-router';
import { ApplicationActions } from '../actions/application.actions';
import firebase from 'firebase/app';
import { fromPromise } from 'rxjs/internal-compatibility';
import { flatMap } from 'rxjs/internal/operators';

const initAuthEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(ApplicationActions.init),
    switchMapTo(
      new Observable<firebase.User | null>(subscriber =>
        auth.onIdTokenChanged(user => {
          subscriber.next(user);
        })
      ).pipe(
        flatMap(user => [
          AuthActions.tokenRefresh(user),
          AuthActions.idTokenRefresh.started({ user, force: false }),
        ])
      )
    )
  );

const idTokenRefreshEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.idTokenRefresh.started),
    switchMap(action =>
      fromPromise(
        action.payload.user
          ? action.payload.user.getIdTokenResult(action.payload.force)
          : Promise.resolve(null)
      ).pipe(
        map(result =>
          AuthActions.idTokenRefresh.done({ params: action.payload, result })
        )
      )
    ),
    catchError(err => of(AuthActions.idTokenRefresh.failed(err)))
  );

const emailLoginSendEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.emailLoginSend.started),
    mergeMap(action =>
      from(
        auth.sendSignInLinkToEmail(action.payload.email, {
          url: `${window.location.origin}/auth/login`,
          handleCodeInApp: true,
        })
      ).pipe(
        map(() => {
          localStorage.setItem('email', action.payload.email);
          return AuthActions.emailLoginSend.done({ params: action.payload });
        }),
        catchError(error =>
          of(
            AuthActions.emailLoginSend.failed({
              params: action.payload,
              error,
            }),
            NotificationActions.error({ message: 'Une erreur est survenue !' })
          )
        )
      )
    )
  );

const emailLoginEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.emailLogin.started),
    mergeMap(action => {
      return from(auth.signInWithEmailLink(action.payload.email)).pipe(
        map(userCredential => {
          localStorage.removeItem('email');
          return AuthActions.emailLogin.done({
            params: action.payload,
            result: userCredential.user,
          });
        }),
        catchError(error =>
          of(
            AuthActions.emailLogin.failed({
              params: action.payload,
              error,
            }),
            NotificationActions.error({ message: 'Une erreur est survenue !' })
          )
        )
      );
    })
  );

const emailPasswordLoginEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.login.started),
    mergeMap(action =>
      from(
        auth.signInWithEmailAndPassword(
          action.payload.email,
          action.payload.password
        )
      ).pipe(
        map(userCredential => {
          return AuthActions.login.done({
            params: action.payload,
            result: userCredential.user,
          });
        }),
        catchError(error =>
          of(
            AuthActions.login.failed({
              params: action.payload,
              error,
            }),
            NotificationActions.error({ message: 'Une erreur est survenue !' })
          )
        )
      )
    )
  );

const forgotPasswordEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.forgotPassword.started),
    mergeMap(action =>
      fromPromise(auth.sendPasswordResetEmail(action.payload.email)).pipe(
        delay(2000),
        mergeMapTo(
          of(
            AuthActions.forgotPassword.done({ params: action.payload }),
            push('/auth/login')
          )
        ),
        startWith(push('/auth/forgot-password/done')),
        catchError(() =>
          of(
            AuthActions.forgotPassword.done({
              params: action.payload,
            }),
            push('/auth/login')
          ).pipe(delay(2000))
        )
      )
    )
  );

const logoutEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.logout.started),
    mergeMap(action =>
      from(auth.signOut()).pipe(
        map(() => {
          return AuthActions.logout.done({
            params: action.payload,
          });
        }),
        catchError(error =>
          of(
            AuthActions.logout.failed(
              {
                params: action.payload,
                error,
              },
              NotificationActions.error({
                message: 'Une erreur est survenue !',
              })
            )
          )
        )
      )
    )
  );

const registerEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(AuthActions.register.started),
    mergeMap(action =>
      from(
        auth.createUserWithEmailAndPassword(
          action.payload.email,
          //TODO implement login with email link
          action.payload.password ?? 'TODO'
        )
      ).pipe(
        map(userCredential => {
          return AuthActions.register.done({
            params: action.payload,
            result: userCredential.user,
          });
        }),
        catchError(error =>
          of(
            AuthActions.register.failed({
              params: action.payload,
              error,
            }),
            NotificationActions.error({ message: 'Une erreur est survenue !' })
          )
        )
      )
    )
  );

export default combineEpics(
  initAuthEpic,
  idTokenRefreshEpic,
  emailLoginSendEpic,
  emailLoginEpic,
  emailPasswordLoginEpic,
  forgotPasswordEpic,
  logoutEpic,
  registerEpic
);
