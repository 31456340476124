import { Entity } from '../utils/entity';
import { EntityRenderDataType } from '../types/entity-render-data-type';

export const projectRenderData = (project: Project): EntityRenderDataType => ({
  key: project.id,
  title: `${project.code}`,
  subTitle: `${project.name}`,
});

export interface Project extends Entity {
  agenceId: string;
  code?: string;
  name?: string;
  salesList?: string[];
  archived?: boolean;
}
