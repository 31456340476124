import { registerEntity } from '../../../config/firebase/firestore/register-entity';
import {
  projectCollectionName,
  projectCollectionRef,
  projectDocumentRef,
} from '../firebase/project.store';
import { CollectionReadParams } from '../../../redux/actions/utils/generate-collection-actions';
import { Project } from '../project.domain';

export interface ProjectCollectionReadParams
  extends CollectionReadParams<Project> {
  agenceId: string;
}
export interface ProjectCollectionGroupReadParams
  extends CollectionReadParams<Project> {}

export const ProjectRegister = registerEntity<
  Project,
  ProjectCollectionReadParams
>(
  'project',
  projectCollectionName,
  ({ agenceId }) => projectCollectionRef(agenceId),
  ['code'],
  project => projectDocumentRef(project.id, project.agenceId)
);
