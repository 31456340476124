import React from 'react';
import { Button, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface EmptyProps {
  description?: string;
  buttonMessage?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const EmptyComponent: React.FC<EmptyProps> = props => (
  <Empty image={<div />} description={props.description}>
    {props.buttonMessage && (
      <Button icon={<PlusOutlined />} type="primary" onClick={props.onClick}>
        {props.buttonMessage}
      </Button>
    )}
  </Empty>
);

export default EmptyComponent;
