import {
  CollectionState,
  CollectionStates,
  DEFAULT_COLLECTION,
  initialCollectionState,
} from '../../reducers/utils/generate-collection-reducer';
import { Entity } from '../../../domain/utils/entity';
import { createSelector, Selector } from 'reselect';
import { DefaultRootState } from 'react-redux';
import '../../store';

const collectionStateSelector = <DocumentType extends Entity>(
  collectionStatesSelector: Selector<
    DefaultRootState,
    CollectionStates<DocumentType>
  >
) => (id = DEFAULT_COLLECTION) =>
  createSelector(
    collectionStatesSelector,
    collectionStates => collectionStates[id] ?? initialCollectionState
  );

const collectionSelector = <DocumentType extends Entity>(
  collectionStatesSelector: Selector<
    DefaultRootState,
    CollectionStates<DocumentType>
  >
) => (id = DEFAULT_COLLECTION) =>
  createSelector(
    collectionStatesSelector,
    collectionStates =>
      (collectionStates[id] ?? initialCollectionState).collection
  );

const loadingSelector = <DocumentType extends Entity>(
  collectionStatesSelector: Selector<
    DefaultRootState,
    CollectionStates<DocumentType>
  >
) => (id = DEFAULT_COLLECTION) =>
  createSelector(
    collectionStatesSelector,
    collectionStates => (collectionStates[id] ?? initialCollectionState).loading
  );

const subscribedSelector = <DocumentType extends Entity>(
  collectionStatesSelector: Selector<
    DefaultRootState,
    CollectionStates<DocumentType>
  >
) => (id = DEFAULT_COLLECTION) =>
  createSelector(
    collectionStatesSelector,
    collectionStates =>
      (collectionStates[id] ?? initialCollectionState).subscribed
  );

const errorSelector = <DocumentType extends Entity>(
  collectionStatesSelector: Selector<
    DefaultRootState,
    CollectionStates<DocumentType>
  >
) => (id = DEFAULT_COLLECTION) =>
  createSelector(
    collectionStatesSelector,
    collectionStates => (collectionStates[id] ?? initialCollectionState).error
  );

export interface CollectionSelectors<DocumentType extends Entity> {
  collectionStateSelector: (
    id?: string
  ) => Selector<DefaultRootState, CollectionState<DocumentType>>;
  collectionSelector: (
    id?: string
  ) => Selector<DefaultRootState, DocumentType[]>;
  loadingSelector: (
    id?: string
  ) => Selector<DefaultRootState, boolean | undefined>;
  subscribedSelector: (
    id?: string
  ) => Selector<DefaultRootState, boolean | undefined>;
  errorSelector: (id?: string) => Selector<DefaultRootState, any>;
}

export const generateCollectionSelectors = <DocumentType extends Entity>(
  collectionStatesSelector: Selector<
    DefaultRootState,
    CollectionStates<DocumentType>
  >
): CollectionSelectors<DocumentType> => {
  return {
    collectionStateSelector: collectionStateSelector(collectionStatesSelector),
    collectionSelector: collectionSelector(collectionStatesSelector),
    loadingSelector: loadingSelector(collectionStatesSelector),
    subscribedSelector: subscribedSelector(collectionStatesSelector),
    errorSelector: errorSelector(collectionStatesSelector),
  };
};
