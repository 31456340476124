import React from 'react';
import { Alert, Upload } from 'antd';
import firebase from 'firebase/app';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  DeleteOutlined,
  FileOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { FileInfo } from '../../../domain/types/file-info';
import { UploadListType } from 'antd/lib/upload/interface';
import { getExtension } from '../../../utils/file.utils';
import './document-upload.component.less';
import { RcFile } from 'antd/es/upload';
import { PDFViewer } from '../../ui/documents/pdf-viewer';

interface DocumentUploadComponentProps {
  fileName?: string;
  fileRef?: firebase.storage.Reference;
  value?: FileInfo;
  onChange?: (value?: FileInfo) => void;
  disableMessage?: string;
  fileSizeLimit?: number;
  type?: UploadListType;
  noPreview?: boolean;
}

export const DocumentUploadComponent = ({
  fileName,
  fileRef,
  value,
  onChange,
  disableMessage,
  fileSizeLimit = 100 * 1024 * 1024,
  type = 'text',
  noPreview = false,
}: DocumentUploadComponentProps) => {
  const [alert, setAlert] = React.useState<string>();
  const [preview, setPreview] = React.useState<FileInfo | undefined>(value);

  // TODO This effect is here to correct a weird bug that happens when accessing an element in a table and then
  // Editing this element, makes the load of the pdf not work.
  React.useEffect(() => {
    if (!value?.url) {
      setPreview(value);
      return;
    }
    fetch(value.url)
      .then(res => res.blob())
      .then(URL.createObjectURL)
      .then(url => setPreview({ ...value, url }));
    return () => {
      if (preview?.url) {
        URL.revokeObjectURL(preview.url);
        setPreview(undefined);
      }
    };
    // eslint-disable-next-line
  }, [value?.url]);

  const handleUpload = React.useCallback(
    async (options: UploadRequestOption) => {
      if (!fileRef || !onChange) {
        return;
      }
      let file = options.file as RcFile;
      const metadata = {
        contentType: file.type,
        size: file.size,
      };

      const uploadTask = fileRef.put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
        next: snapshot =>
          options.onProgress &&
          options.onProgress({
            percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          } as any),
        error: error => options.onError && options.onError(error),
        complete: async () => {
          const url: string = await fileRef.getDownloadURL();
          options.onSuccess && options.onSuccess({}, {} as any);
          onChange({
            url,
            contentType: file.type,
            name: fileName
              ? `${fileName}.${getExtension(file.type)}`
              : file.name,
            path: fileRef.fullPath,
          });
        },
      });
    },
    [fileName, fileRef, onChange]
  );

  const beforeUpload = React.useCallback(
    (file: File) => {
      if (!fileRef) {
        setAlert('Une erreur est survenue !');
        return false;
      }
      if (file.size > fileSizeLimit) {
        setAlert(
          `La taille du fichier ne doit pas excéder ${fileSizeLimit / 1024}kb !`
        );
        return false;
      } else {
        setAlert(undefined);
      }
      return true;
    },
    [fileRef, fileSizeLimit]
  );

  return (
    <>
      {!preview && (
        <Upload.Dragger
          disabled={!!disableMessage || !fileRef}
          customRequest={handleUpload}
          accept="*"
          name="file-upload"
          listType={type}
          beforeUpload={beforeUpload}
          onPreview={file =>
            setPreview({
              url: file.url,
              contentType: file.type,
              name: file.name,
            })
          }
          onRemove={() => {
            onChange && onChange(undefined);
            setPreview(undefined);
          }}
        >
          <div>
            <p>
              <UploadOutlined
                style={{
                  fontSize: '48px',
                  color: disableMessage ? '#575757' : '#c72626',
                  margin: '1rem',
                }}
              />
            </p>
            {disableMessage ? (
              <p
                style={{
                  margin: '4px 0',
                  color: 'hsla(0,0%,66%,0.85)',
                  fontSize: '16px',
                }}
              >
                {disableMessage}
              </p>
            ) : (
              <>
                <p
                  style={{
                    margin: '4px 0',
                    color: 'hsla(0,0%,100%,.85)',
                    fontSize: '16px',
                  }}
                >
                  Cliquez {!isMobile && 'ou Glissez le fichier'} ici !
                </p>
                <p
                  style={{
                    color: 'hsla(0,0%,100%,.45)',
                    fontSize: '14px',
                  }}
                >
                  La taille du fichier doit être inférieure à 100 mega.
                </p>
              </>
            )}

            {alert && <Alert type="error" message={alert} />}
          </div>
        </Upload.Dragger>
      )}
      {preview && (
        <div className={'document-preview'} style={{ position: 'relative' }}>
          <DeleteOutlined
            style={{
              position: 'absolute',
              right: 0,
              color: 'red',
              zIndex: 9999,
              fontSize: '1rem',
            }}
            onClick={() => {
              onChange && onChange(undefined);
              setPreview(undefined);
            }}
          />
          {noPreview ? (
            <>
              <FileOutlined /> {preview.name}
            </>
          ) : preview.contentType === 'application/pdf' ? (
            <PDFViewer file={preview.url} />
          ) : preview.contentType?.startsWith('image/') ? (
            <img
              alt={`Preview ${preview.name}`}
              style={{ width: '100%' }}
              src={preview.url}
            />
          ) : (
            <span>{preview.name}</span>
          )}
        </div>
      )}
    </>
  );
};
