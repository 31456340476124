import { from, Observable, of } from 'rxjs';
import { NotificationActions } from '../../actions/notification.actions';
import { AnyAction } from 'typescript-fsa';
import { ofAction } from './of-action';
import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import firebase from 'firebase/app';
import { DocumentActions } from '../../actions/utils/generate-document-actions';
import { AnalyticsActions } from '../../actions/analytics.actions';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import { Entity } from '../../../domain/utils/entity';
import { emptyArray } from '../../../utils/constants';
import { generateSearchArray } from './search';
import { firestore } from '../../../config/firebase/firebase.config';

export const generateDocumentEpic = <
  DocumentType extends Entity,
  ErrorType extends Error = Error
>(
  getDocument: (
    document: DocumentType
  ) => firebase.firestore.DocumentReference<DocumentType>,
  documentActionsCreator: DocumentActions<DocumentType, ErrorType>,
  searchFields: Array<(keyof DocumentType & string) | string> = emptyArray()
) => {
  const documentReadEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.read.started),
      mergeMap(action =>
        from(getDocument(action.payload).get()).pipe(
          map(result =>
            documentActionsCreator.read.done({
              params: action.payload,
              result,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.read.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La lecture a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${getDocument(action.payload).path}`,
              })
            )
          )
        )
      )
    );

  const documentWriteEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.write.started),
      mergeMap(action => {
        return from(
          getDocument(action.payload).set(
            {
              creationDate: firebase.firestore.Timestamp.now(),
              modificationDate: firebase.firestore.Timestamp.now(),
              ...action.payload,
              _search: generateSearchArray(searchFields, action.payload),
            },
            { merge: true }
          )
        ).pipe(
          ignoreElements(),
          // We dispatch write done immediately to support offline mode.
          startWith(
            documentActionsCreator.write.done({
              params: action.payload,
              result: getDocument(action.payload).id,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.write.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La sauvegarde a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${getDocument(action.payload).path}`,
              })
            )
          )
        );
      })
    );

  const documentWriteTransactionEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.writeTransaction.started),
      mergeMap(action => {
        const documentRef = getDocument(action.payload.document);

        const document = {
          creationDate: firebase.firestore.Timestamp.now(),
          modificationDate: firebase.firestore.Timestamp.now(),
          ...action.payload.document,
          _search: generateSearchArray(searchFields, action.payload.document),
        };
        return from(
          firestore.runTransaction<string>(transaction =>
            action.payload.callback(transaction, documentRef, document)
          )
        ).pipe(
          map(result =>
            documentActionsCreator.writeTransaction.done({
              params: action.payload,
              result,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.writeTransaction.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La Transaction a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${getDocument(action.payload.document).path}`,
              })
            )
          )
        );
      })
    );

  const documentUpdateEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.update.started),
      mergeMap(action => {
        const search = generateSearchArray(searchFields, action.payload);
        return from(
          getDocument(action.payload.before).update({
            ...action.payload.after,
            modificationDate: firebase.firestore.Timestamp.now(),
            ...(search.length > 0 && {
              _search: firebase.firestore.FieldValue.arrayUnion(
                ...generateSearchArray(searchFields, action.payload)
              ),
            }),
          })
        ).pipe(
          ignoreElements(),
          // We dispatch update done immediately to support offline mode.
          startWith(
            documentActionsCreator.update.done({
              params: action.payload,
              result: getDocument(action.payload.before).id,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.update.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La sauvegarde a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${getDocument(action.payload.before).path}`,
              })
            )
          )
        );
      })
    );

  const documentUpdateTransactionEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.updateTransaction.started),
      mergeMap(action => {
        const documentRef = getDocument(action.payload.updateParams.before);
        const after = {
          modificationDate: firebase.firestore.Timestamp.now(),
          ...action.payload.updateParams.after,
          _search: generateSearchArray(
            searchFields,
            action.payload.updateParams.after
          ),
        };
        return from(
          firestore.runTransaction<string>(transaction =>
            action.payload.callback(transaction, documentRef, {
              before: action.payload.updateParams.before,
              after,
            })
          )
        ).pipe(
          map(result =>
            documentActionsCreator.updateTransaction.done({
              params: action.payload,
              result,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.updateTransaction.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La Transaction a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${
                  getDocument(action.payload.updateParams.before).path
                }`,
              })
            )
          )
        );
      })
    );

  const documentDeleteEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.delete.started),
      mergeMap(action => {
        return from(getDocument(action.payload).delete()).pipe(
          ignoreElements(),
          // We dispatch delete done immediately to support offline mode.
          startWith(
            documentActionsCreator.delete.done({
              params: action.payload,
              result: getDocument(action.payload).id,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.delete.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La suppression a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${getDocument(action.payload).path}`,
              })
            )
          )
        );
      })
    );

  const documentDeleteTransactionEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.deleteTransaction.started),
      mergeMap(action => {
        return from(
          firestore.runTransaction<string>(transaction =>
            action.payload.callback(
              transaction,
              getDocument(action.payload.document),
              action.payload.document
            )
          )
        ).pipe(
          map(result =>
            documentActionsCreator.deleteTransaction.done({
              params: action.payload,
              result,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.deleteTransaction.failed({
                params: action.payload,
                error,
              }),
              NotificationActions.error({
                message: 'La Transaction a échoué !',
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${getDocument(action.payload.document).path}`,
              })
            )
          )
        );
      })
    );

  const documentSubscribeEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofAction(documentActionsCreator.subscribe.started),
      switchMap(action =>
        new Observable<firebase.firestore.DocumentSnapshot<DocumentType>>(
          observer => {
            const unsubscribe = getDocument(action.payload).onSnapshot(
              snapshot => observer.next(snapshot),
              error => observer.error(error),
              () => observer.complete()
            );
            return () => {
              unsubscribe();
            };
          }
        ).pipe(
          map(result =>
            documentActionsCreator.read.done({
              params: action.payload,
              result,
            })
          ),
          startWith(
            documentActionsCreator.subscribe.done({
              params: action.payload,
            })
          ),
          catchError(error =>
            of(
              documentActionsCreator.read.failed({
                params: action.payload,
                error,
              }),
              documentActionsCreator.subscribe.failed({
                params: action.payload,
                error,
              }),
              AnalyticsActions.exception({
                description: error.message,
                type: error.name,
                stack: error.stack,
                document: `${getDocument(action.payload).path}`,
              })
            )
          ),
          takeUntil(
            action$.pipe(
              ofAction(documentActionsCreator.unsubscribe),
              filter(
                subscribeAction =>
                  isNil(subscribeAction.payload) ||
                  isEqual(subscribeAction.payload, action.payload)
              )
            )
          )
        )
      )
    );

  return combineEpics(
    documentReadEpic,
    documentWriteEpic,
    documentWriteTransactionEpic,
    documentUpdateEpic,
    documentUpdateTransactionEpic,
    documentDeleteEpic,
    documentDeleteTransactionEpic,
    documentSubscribeEpic
  );
};
