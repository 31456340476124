import actionCreatorFactory, {
  ActionCreator,
  ActionCreatorFactory,
  AsyncActionCreators,
} from 'typescript-fsa';

import firebase from 'firebase/app';
import { Entity } from '../../../domain/utils/entity';

const ActionTypes = {
  READ: 'READ',
  WRITE: 'WRITE',
  WRITE_TRANSACTION: 'WRITE_TRANSACTION',
  UPDATE: 'UPDATE',
  UPDATE_TRANSACTION: 'UPDATE_TRANSACTION',
  DELETE: 'DELETE',
  DELETE_TRANSACTION: 'DELETE_TRANSACTION',
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  CLEAN_STATE: 'CLEAN_STATE',
  RESET_STATE: 'RESET_STATE',
};

export interface UpdateParams<DocumentType extends Entity> {
  before: DocumentType;
  after: Partial<DocumentType>;
}

export interface UpdateTransactionParams<DocumentType extends Entity> {
  updateParams: UpdateParams<DocumentType>;
  callback: (
    transaction: firebase.firestore.Transaction,
    documentRef: firebase.firestore.DocumentReference<DocumentType>,
    param: UpdateParams<DocumentType>
  ) => Promise<string>;
}

export interface TransactionParams<DocumentType extends Entity> {
  document: DocumentType;
  callback: (
    transaction: firebase.firestore.Transaction,
    documentRef: firebase.firestore.DocumentReference<DocumentType>,
    document: DocumentType
  ) => Promise<string>;
}

export interface DocumentActions<
  DocumentType extends Entity,
  ErrorType extends Error = Error
> {
  read: AsyncActionCreators<
    DocumentType,
    firebase.firestore.DocumentSnapshot<DocumentType>,
    ErrorType
  >;
  write: AsyncActionCreators<DocumentType, string, ErrorType>;
  writeTransaction: AsyncActionCreators<
    TransactionParams<DocumentType>,
    string,
    ErrorType
  >;
  update: AsyncActionCreators<UpdateParams<DocumentType>, string, ErrorType>;
  updateTransaction: AsyncActionCreators<
    UpdateTransactionParams<DocumentType>,
    string,
    ErrorType
  >;
  delete: AsyncActionCreators<DocumentType, string, ErrorType>;
  deleteTransaction: AsyncActionCreators<
    TransactionParams<DocumentType>,
    string,
    ErrorType
  >;
  subscribe: AsyncActionCreators<DocumentType, void, ErrorType>;
  unsubscribe: ActionCreator<DocumentType | void>;
  cleanState: ActionCreator<string>;
  resetState: ActionCreator<string | void>;
  __factory__: ActionCreatorFactory;
}

export const generateDocumentActions = <
  DocumentType extends Entity,
  ErrorType extends Error = Error
>(
  prefix: string
): DocumentActions<DocumentType, ErrorType> => {
  const factory = actionCreatorFactory(prefix);

  return {
    read: factory.async(ActionTypes.READ),
    write: factory.async(ActionTypes.WRITE),
    writeTransaction: factory.async(ActionTypes.WRITE_TRANSACTION),
    update: factory.async(ActionTypes.UPDATE),
    updateTransaction: factory.async(ActionTypes.UPDATE_TRANSACTION),
    delete: factory.async(ActionTypes.DELETE),
    deleteTransaction: factory.async(ActionTypes.DELETE_TRANSACTION),
    subscribe: factory.async(ActionTypes.SUBSCRIBE),
    unsubscribe: factory(ActionTypes.UNSUBSCRIBE),
    cleanState: factory(ActionTypes.CLEAN_STATE),
    resetState: factory(ActionTypes.RESET_STATE),
    __factory__: factory as ActionCreatorFactory,
  };
};
