import { AuthState } from '../reducers/auth.reducer';
import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

export const authSelector = (state: DefaultRootState) =>
  state.auth as AuthState;

export const authUserSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user
);

export const userIdSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.uid
);

export const selectedCompanySelector = createSelector(
  authSelector,
  (state: AuthState) => (state?.idToken?.claims || {})['CURRENT_COMPANY']
);

export const userRoleSelector = createSelector(
  authSelector,
  (state: AuthState) => (state?.idToken?.claims || {})['USER_ROLE']
);

export const displayNameSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.displayName
);

export const photoURLSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.photoURL
);

export const emailSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.email ?? undefined
);

export const emailVerifiedSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.emailVerified
);

export const creationTimeSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.metadata.creationTime
);

export const lastSignInTimeSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.metadata.lastSignInTime
);

export const phoneNumberSelector = createSelector(
  authSelector,
  (state: AuthState) => state?.user?.phoneNumber
);
