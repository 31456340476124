import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import pull from 'lodash/pull';
import unset from 'lodash/unset';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import firebase from 'firebase/app';

/* eslint-disable */
export const validateMessages = {
  required: "'${name}' est requis !",
  types: {
    email: 'Veillez saisir un email valide.',
    integer: "'${name}' doit être un chiffre valide.",
    float: "'${name}' doit être un chiffre à valeur flotante.",
    string: "'${name}' doit être une chaine de caractères",
    enum: '',
    date: "'${name}' doit être une date valide",
    url: "'${name}' doit être une URL valide",
  },
  number: {
    range: 'Doit être entre ${min} et ${max}',
  },
};
/* eslint-enable */

export const symbolRegEx = /[$-/@&:-?{-~!^_`[\]]/;
export const lowercaseRegEx = /[a-z]+/;
export const uppercaseRegEx = /[A-Z]+/;
export const numberRegEx = /[0-9]+/;
export const passwordMinLength = 8;

export const hasUppercase = (value: string): boolean => {
  return lowercaseRegEx.test(value) && uppercaseRegEx.test(value);
};

export const hasLowercase = (value: string): boolean => {
  return lowercaseRegEx.test(value);
};

export const hasNumber = (value: string): boolean => {
  return numberRegEx.test(value);
};

export const hasSymbol = (value: string): boolean => {
  return symbolRegEx.test(value);
};

export const hasMinLength = (value: string): boolean => {
  return value.length >= passwordMinLength;
};

export const PasswordStrengthPopup: React.FC = props => <div>PWD</div>;
//   `
//   font-size: 11px;
//   line-height: 18px;
//   .is-passed {
//     color: ${props => props.theme.color.success};
//   }
//   .not-passed {
//     color: ${props => props.theme.color.error};
//   }
// `;

export const Strength: React.FC = () => <div>Strength</div>;
//   `
//   ul.strengthBar {
//     width: 100%;
//     padding: 0;
//     margin: 4px 0 0;
//     height: 4px;
//     .point {
//       display: inline-block;
//       width: 20%;
//       margin: 0;
//       height: 100%;
//       border-radius: 0;
//       background: #ddd;
//       &:last-child {
//         margin: 0 !important;
//       }
//     }
//   }
// `;

export const strengthRules = (password: string): React.ReactElement => (
  <PasswordStrengthPopup>
    <div className={hasMinLength(password) ? 'is-passed' : 'not-passed'}>
      <CheckCircleOutlined /> {passwordMinLength} lettres ou plus
    </div>
    <div className={hasUppercase(password) ? 'is-passed' : 'not-passed'}>
      <CheckCircleOutlined /> Lettres majuscules et minuscules
    </div>
    <div className={hasNumber(password) ? 'is-passed' : 'not-passed'}>
      <CheckCircleOutlined /> Au moins un numéro
    </div>
    <div className={hasSymbol(password) ? 'is-passed' : 'not-passed'}>
      <CheckCircleOutlined /> Au moins un caractère spécial
    </div>
    <p />
    Sécurité:
    {passwordStrengthBar(password)}
  </PasswordStrengthPopup>
);

export const passwordStrengthBar = (password: string) => {
  const colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];

  const measureStrength = (p: string): number => {
    let force = 0;
    const flags = {
      lowerLetters: lowercaseRegEx.test(p),
      upperLetters: uppercaseRegEx.test(p),
      numbers: numberRegEx.test(p),
      symbols: symbolRegEx.test(p),
    };

    const passedMatches = Object.values(flags).filter(
      (isMatchedFlag: boolean) => isMatchedFlag
    ).length;

    force += 2 * p.length + (p.length >= passwordMinLength ? 1 : 0);
    force += passedMatches * 10;

    // penalty (short password)
    force = p.length <= 6 ? Math.min(force, 10) : force;

    // penalty (poor variety of characters)
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 40) : force;

    return force;
  };

  interface Force {
    idx: number;
    col: string;
  }
  const getColor = (s: number): Force => {
    let idx = 0;
    if (s <= 10) {
      idx = 0;
    } else if (s <= 20) {
      idx = 1;
    } else if (s <= 30) {
      idx = 2;
    } else if (s <= 40) {
      idx = 3;
    } else {
      idx = 4;
    }
    return { idx: idx + 1, col: colors[idx] };
  };

  const getPoints = (force: Force) => {
    const pts = [];
    for (let i = 0; i < 5; i++) {
      pts.push(
        <li
          key={i}
          className="point"
          style={
            i < force.idx
              ? { backgroundColor: force.col }
              : { backgroundColor: '#DDD' }
          }
        />
      );
    }
    return pts;
  };

  const strength = getColor(measureStrength(password));
  const points = getPoints(strength);

  return (
    <Strength>
      <ul className="strengthBar">{points}</ul>
    </Strength>
  );
};

export const removeUndefinedRecursively = (
  value: any,
  options?: { deleteField?: boolean; deleteEmpty?: boolean }
) => {
  const { deleteField = false, deleteEmpty = false } = options || {};
  if (isArray(value)) {
    pull(value, undefined);
  }
  each(value, (v, k) => {
    if (isUndefined(v)) {
      deleteField
        ? (value[k] = firebase.firestore.FieldValue.delete())
        : unset(value, k);
    } else if ((isPlainObject(v) || isArray(v)) && isEmpty(v) && deleteEmpty) {
      value[k] = firebase.firestore.FieldValue.delete();
    } else if (isPlainObject(v) || isArray(v)) {
      removeUndefinedRecursively(v);
    }
  });
  return value;
};

export const removeFirestoreFieldsRecursively = (value: any) => {
  const deleteField = firebase.firestore.FieldValue.delete();
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  each(value, (v, k) => {
    if (isEqual(v, deleteField) || isEqual(v, serverTimestamp)) {
      unset(value, k);
    }
  });
  return value;
};
