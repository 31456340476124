import actionCreatorFactory from 'typescript-fsa';
import firebase from 'firebase/app';

const factory = actionCreatorFactory('AUTH');

export const AuthActionTypes = {
  TOKEN_REFRESH: 'TOKEN_REFRESH',
  ID_TOKEN_REFRESH: 'ID_TOKEN_REFRESH',
  LOGIN: 'LOGIN',
  EMAIL_LOGIN_SEND: 'EMAIL_LOGIN_SEND',
  EMAIL_LOGIN: 'EMAIL_LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  LOGOUT: 'LOGOUT',
  REGISTER: 'REGISTER',
};

export interface LoginParams {
  email: string;
  password: string;
}

export interface EmailLoginParams {
  email: string;
}

export interface ForgotPasswordParam {
  email: string;
}

export interface AuthError extends Error {}

export const AuthActions = {
  tokenRefresh: factory<firebase.User | null>(AuthActionTypes.TOKEN_REFRESH),
  idTokenRefresh: factory.async<
    { user: firebase.User | null; force: boolean },
    firebase.auth.IdTokenResult | null,
    AuthError
  >(AuthActionTypes.ID_TOKEN_REFRESH),
  register: factory.async<LoginParams, firebase.User | null, AuthError>(
    AuthActionTypes.REGISTER
  ),
  login: factory.async<LoginParams, firebase.User | null, AuthError>(
    AuthActionTypes.LOGIN
  ),
  emailLoginSend: factory.async<EmailLoginParams, void, AuthError>(
    AuthActionTypes.EMAIL_LOGIN_SEND
  ),
  emailLogin: factory.async<EmailLoginParams, firebase.User | null, AuthError>(
    AuthActionTypes.EMAIL_LOGIN
  ),
  forgotPassword: factory.async<ForgotPasswordParam, void, AuthError>(
    AuthActionTypes.FORGOT_PASSWORD
  ),
  logout: factory.async<void, undefined, AuthError>(AuthActionTypes.LOGOUT),
};
