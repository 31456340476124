import { Observable } from 'rxjs';

import { NotificationActions } from '../actions/notification.actions';
import { AnyAction } from 'typescript-fsa';
import { ignoreElements, tap } from 'rxjs/operators';
import { ofAction } from './utils/of-action';
import { combineEpics } from 'redux-observable';

import { message } from 'antd';

const successNotification = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(NotificationActions.success),
    tap(action => {
      message.success(action.payload.message);
    }),
    ignoreElements()
  );

const warningNotification = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(NotificationActions.warning),
    tap(action => {
      message.warning(action.payload.message);
    }),
    ignoreElements()
  );

const errorNotification = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(NotificationActions.error),
    tap(action => {
      message.error(action.payload.message);
    }),
    ignoreElements()
  );

export default combineEpics(
  successNotification,
  warningNotification,
  errorNotification
);
