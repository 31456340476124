import appReducer from './app.reducer';
import auth from './auth.reducer';
import database from './database.reducer';
import { UserRegister } from '../../domain/user/redux/user-register';
import { ProjectRegister } from '../../domain/project/redux/project-register';
import { AgenceRegister } from '../../domain/agence/redux/agence-register';
import { SalesRegister } from '../../domain/sales/redux/sales-register';
import { FileRegister } from '../../domain/file/redux/file-register';

const rootReducer = {
  ...appReducer,
  ...auth,
  ...database,
  ...UserRegister.reducers,
  ...AgenceRegister.reducers,
  ...ProjectRegister.reducers,
  ...SalesRegister.reducers,
  ...FileRegister.reducers,
};

export default rootReducer;
