import React from 'react';
import { isMobile } from 'react-device-detect';

import './content.component.less';

export const Content: React.FC = props => (
  <div
    className={`content-component ${isMobile && 'content-component-mobile'}`}
  >
    {props.children}
  </div>
);
