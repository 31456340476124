import React from 'react';
import { LeftMenu } from './left-menu.component';
import { RightMenu } from './right-menu.component';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../../images/logo.svg';
import './header.component.less';
import { useSelector } from 'react-redux';
import { locationPathnameSelector } from '../../../redux/selectors/router.selector';
import { MenuOutlined } from '@ant-design/icons';

export const Header: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const pathname = useSelector(locationPathnameSelector);

  React.useEffect(() => {
    setVisible(false);
  }, [pathname]);

  const showDrawer = React.useCallback(() => {
    setVisible(true);
  }, []);

  const onClose = React.useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <nav className="header">
      <div className="logo">
        <Link to="/">
          <LogoSVG />
        </Link>
      </div>
      <div className="menuCon">
        <div className="leftMenu">
          <LeftMenu />
        </div>
        <div className="rightMenu">
          <RightMenu />
        </div>
        <MenuOutlined className="barsMenu" onClick={showDrawer} />
        <Drawer
          title="Menu"
          placement="right"
          closable={true}
          onClose={onClose}
          visible={visible}
        >
          <LeftMenu />
          <RightMenu />
        </Drawer>
      </div>
    </nav>
  );
};
