import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { history } from './redux/config/router';
import { ConnectedRouter } from 'connected-react-router';
import { Application } from './components/application';
import { ConfigProvider } from 'antd';
import { validateMessages } from './components/utils/form.helpers';
import fr_FR from 'antd/es/locale/fr_FR';
import 'moment/locale/fr';
import './style/theme.less';
import 'antd/lib/message/style';
import 'antd/lib/notification/style';
import { MediaQueryProvider } from './style/media-query.context';

const App = () => (
  <MediaQueryProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ConfigProvider
          locale={fr_FR}
          form={{ validateMessages }}
          input={{ autoComplete: 'off' }}
          componentSize={'small'}
        >
          <Application />
        </ConfigProvider>
      </ConnectedRouter>
    </Provider>
  </MediaQueryProvider>
);

export default App;
