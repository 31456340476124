import React from 'react';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { removeUndefinedRecursively } from '../utils/form.helpers';
import { FormContainerComponent } from './form-container.component';
import { generateRandomId } from '../../config/firebase/utils/random';
import { Entity } from '../../domain/utils/entity';
import { FormInstance } from 'antd/es/form';
import { ActionCreator, AsyncActionCreators } from 'typescript-fsa';
import { ResponsiveButton } from '../ui/button/responsive-button.component';
import { DocumentSelectors } from '../../redux/selectors/utils/document.selector';
import { TransactionParams } from '../../redux/actions/utils/generate-document-actions';
import firebase from 'firebase/app';

export interface FormTransactionCreateProps<DocumentType extends Entity> {
  title: string;
  subTitle: string;
  documentStateSelector: DocumentSelectors<DocumentType>;
  writeActionCreator?: AsyncActionCreators<DocumentType, string, Error>;
  writeTransactionActionCreator?: AsyncActionCreators<
    TransactionParams<DocumentType>,
    string,
    Error
  >;
  transactionCallback?: (
    transaction: firebase.firestore.Transaction,
    documentRef: firebase.firestore.DocumentReference<DocumentType>,
    document: DocumentType
  ) => Promise<string>;
  cleanActionCreator: ActionCreator<string>;
  documentId?: string;
  initialValues: Partial<DocumentType>;
  onDone?: (newDocumentId: string) => void;
  children:
    | React.ReactNode
    | ((newId: string, form?: FormInstance) => React.ReactNode);
  onBack?: () => void;
  renderFooter?: (form: FormInstance) => React.ReactNode;
}

export const FormTransactionCreateComponent = <DocumentType extends Entity>({
  title,
  subTitle,
  documentStateSelector,
  writeActionCreator,
  writeTransactionActionCreator,
  transactionCallback,
  cleanActionCreator,
  documentId,
  initialValues,
  onDone = () => {},
  children,
  renderFooter,
  onBack,
}: FormTransactionCreateProps<DocumentType>) => {
  const dispatch = useDispatch();

  const [formEdited, setFormEdited] = React.useState(false);

  const [form] = Form.useForm();

  const newDocumentId = React.useMemo(() => documentId ?? generateRandomId(), [
    documentId,
  ]);

  const documentState = useSelector(
    documentStateSelector.documentStateSelector(newDocumentId)
  );

  React.useEffect(() => {
    return () => {
      if (cleanActionCreator) dispatch(cleanActionCreator(newDocumentId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (newDocumentId && documentState.writeDocumentId === newDocumentId) {
      onDone(newDocumentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentState.writeDocumentId]);

  const onFinish = (documentForm: any) => {
    setFormEdited(false);
    documentForm = removeUndefinedRecursively(documentForm);
    if (writeActionCreator) {
      dispatch(
        writeActionCreator.started({
          ...removeUndefinedRecursively(initialValues),
          ...documentForm,
          id: newDocumentId,
        })
      );
    } else if (writeTransactionActionCreator && transactionCallback) {
      dispatch(
        writeTransactionActionCreator.started({
          callback: transactionCallback,
          document: {
            ...removeUndefinedRecursively(initialValues),
            ...documentForm,
            id: newDocumentId,
          },
        })
      );
    }
  };

  const onFieldsChange = () => {
    setFormEdited(true);
  };

  return (
    <FormContainerComponent
      title={title}
      subTitle={subTitle}
      loading={documentState.loading}
      footer={
        renderFooter ? (
          renderFooter(form)
        ) : (
          <ResponsiveButton icon={<PlusOutlined />} onClick={form.submit}>
            {title}
          </ResponsiveButton>
        )
      }
      blockNavigation={formEdited}
      onBack={onBack}
    >
      <Form
        colon={true}
        form={form}
        name={title}
        onFinish={onFinish}
        layout={'vertical'}
        onFieldsChange={onFieldsChange}
        initialValues={initialValues}
        children={
          typeof children === 'function'
            ? children(newDocumentId, form)
            : children
        }
      />
    </FormContainerComponent>
  );
};
