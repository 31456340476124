import { firestore } from '../../../config/firebase/firebase.config';
import firebase from 'firebase/app';
import { Agence } from '../agence.domain';

export const agenceCollectionName = 'agences';

export const agenceCollectionPath = () => {
  return agenceCollectionName;
};

export const agenceDocumentPath = (agenceId?: string) => {
  return `${agenceCollectionPath()}/${agenceId}`;
};

export const agenceCollectionRef = () => {
  return firestore.collection(
    agenceCollectionPath()
  ) as firebase.firestore.CollectionReference<Agence>;
};

export const agenceDocumentRef = (agenceId: string) => {
  return agenceCollectionRef().doc(agenceId);
};
