import React from 'react';
import './table-display.component.less';
import {
  DeleteFilled,
  EditFilled,
  EllipsisOutlined,
  EyeOutlined,
  MenuOutlined,
  PlusOutlined,
  SettingOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, Table } from 'antd';
import { Switch } from 'react-router-dom';
import { ErrorBoundaryRoute } from '../router/error-boundary-route';
import { useSelector } from 'react-redux';
import {
  CollectionActions,
  CollectionReadParams,
} from '../../redux/actions/utils/generate-collection-actions';
import { ColumnsType } from 'antd/es/table/interface';
import { CollectionSelectors } from '../../redux/selectors/utils/collection.selector';
import { DocumentActions } from '../../redux/actions/utils/generate-document-actions';
import kebabCase from 'lodash/kebabCase';
import { Modal } from '../utils/modal';
import { Entity } from '../../domain/utils/entity';
import { emptyArray } from '../../utils/constants';
import { useEntityPaths } from '../utils/use-entity-paths';
import { useCollectionSubscribedSearch } from '../../hooks/use-collection-subscribed-search.hook';
import { ResponsiveButton } from '../ui/button/responsive-button.component';
import { useParams } from '../router/use-params.hook';

export interface TableDisplayProps<
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>
> {
  title?: string;
  search?: boolean;
  scroll?: {
    x?: number | true | string;
    y?: number | string;
  };
  columns: ColumnsType<DocumentType>;
  readParams: ReadParamType;
  documentActions: DocumentActions<DocumentType>;
  collectionActions: CollectionActions<ReadParamType, DocumentType>;
  collectionStateSelector: CollectionSelectors<DocumentType>;
  entityName: string;
  collectionName: string;
  renderCreate?: (onDone: (documentId: string) => void) => React.ReactNode;
  renderEdit?: (
    document: DocumentType,
    onDone: (documentId: string) => void
  ) => React.ReactNode;
  renderDetails?: (
    document: DocumentType,
    onEdit: (documentId: string) => void
  ) => React.ReactNode;
  // Will be displayed in a button group
  tableOps?: React.ReactNode;
  // Will be displayed in a menu
  rowOps?: (document: DocumentType) => React.ReactNode;
  rowOpsViews?: (
    document?: DocumentType
  ) => React.ReactNode | React.ReactNode[];
  // Will be displayed in a menu
  selectionOps?: (
    documents: DocumentType[],
    resetSelection: () => void
  ) => React.ReactNode;
  computeRowClassNames?: (document: DocumentType) => string[];
}

export const TableDisplayComponent = <
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>
>({
  title,
  search = true,
  scroll,
  columns,
  readParams,
  documentActions,
  collectionActions,
  collectionStateSelector,
  entityName,
  collectionName,
  renderCreate,
  renderEdit,
  renderDetails,
  tableOps,
  rowOps,
  rowOpsViews,
  selectionOps,
  computeRowClassNames,
}: TableDisplayProps<DocumentType, ReadParamType>) => {
  const collectionState = useSelector(
    collectionStateSelector.collectionStateSelector()
  );
  const {
    loading = false,
    collection = emptyArray<DocumentType>(),
  } = collectionState;
  const [items, setItems] = React.useState(collection);
  const [selectedItem, setSelectedItem] = React.useState<DocumentType>();
  const [selectedDocuments, setSelectedDocuments] = React.useState(
    emptyArray<DocumentType>()
  );
  const {
    basePage,
    entityPage,
    entityEdit,
    entityDelete,
    documentId,
    path,
  } = useEntityPaths(collectionName, documentActions);

  const { params, setParams } = useParams();
  const action = params[kebabCase(collectionName)];

  const { limit, setLimit, setSearchValue } = useCollectionSubscribedSearch(
    collectionActions,
    readParams
  );

  React.useEffect(() => {
    setItems(collection);
  }, [collection]);

  React.useEffect(() => {
    if (documentId) {
      setSelectedItem(items.find(item => item.id === documentId));
    } else {
      setSelectedItem(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, documentId]);

  const columnsWithActions = React.useMemo<ColumnsType<DocumentType>>(() => {
    return [
      ...columns,
      {
        title: <SettingOutlined />,
        width: '80px',
        align: 'center',
        render: (item: DocumentType) => (
          <Dropdown
            trigger={['click', 'hover']}
            overlay={
              <Menu onClick={e => e.domEvent.stopPropagation()}>
                <Menu.Item
                  key={'preview'}
                  icon={<EyeOutlined />}
                  onClick={() => entityPage(item.id)}
                >
                  Aperçu
                </Menu.Item>
                {rowOps
                  ? rowOps(item)
                  : renderEdit && (
                      <>
                        <Menu.Divider />
                        <Menu.Item
                          key="edit"
                          icon={<EditFilled />}
                          onClick={() => entityEdit(item.id)}
                        >
                          Éditer
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          danger
                          key="delete"
                          icon={<DeleteFilled />}
                          onClick={() => entityDelete(item)}
                        >
                          Supprimer
                        </Menu.Item>
                      </>
                    )}
              </Menu>
            }
          >
            <Button
              type={'text'}
              onClick={e => e.stopPropagation()}
              icon={<EllipsisOutlined />}
              style={{ width: '100%' }}
            />
          </Dropdown>
        ),
      },
    ];
  }, [columns, entityDelete, entityEdit, entityPage, renderEdit, rowOps]);

  return (
    <div
      className={`table-display-component ${documentId ? 'selected-item' : ''}`}
    >
      <div className={'table-title'}>
        <h1 className={'title'}>{title}</h1>
        <Button.Group>
          {tableOps}
          {renderCreate && (
            <ResponsiveButton
              responsive
              className={'add-button'}
              icon={<PlusOutlined />}
              onClick={() =>
                setParams({ ...params, [kebabCase(collectionName)]: 'new' })
              }
            >
              {entityName}
            </ResponsiveButton>
          )}
        </Button.Group>
      </div>
      <div className={'table-actions'}>
        <div className={'action-bar'}>
          {selectionOps && (
            <Menu
              mode={'horizontal'}
              selectable={false}
              overflowedIndicator={<MenuOutlined />}
            >
              {selectionOps(selectedDocuments, () =>
                setSelectedDocuments(emptyArray())
              )}
            </Menu>
          )}
        </div>
        {search && (
          <div className={'table-search-bar'}>
            <Input.Search
              size={'small'}
              placeholder={'Recherche ...'}
              onSearch={setSearchValue}
              onChange={e =>
                setItems(
                  collection.filter(
                    v =>
                      !e.target.value.trim() ||
                      JSON.stringify(v)
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase())
                  )
                )
              }
            />
          </div>
        )}
      </div>
      <div className={'table'}>
        <Table
          locale={{
            emptyText: (
              <>
                <StopOutlined style={{ display: 'block' }} />
                Aucune Donnée
              </>
            ),
          }}
          rowSelection={
            selectionOps
              ? {
                  selectedRowKeys: selectedDocuments.map(d => d.id),
                  onChange: (_, rows) => {
                    setSelectedDocuments(rows);
                  },
                }
              : undefined
          }
          size="small"
          rowClassName={(document, index) => {
            if (selectedDocuments.map(doc => doc.id).includes(document.id)) {
              return 'table-row-selected';
            }
            if (computeRowClassNames) {
              const classNames = computeRowClassNames(document);
              if (classNames && classNames.length > 0) {
                return classNames.join(' ');
              }
            }
            return index % 2 === 0 ? 'table-row-even' : 'table-row-odd';
          }}
          loading={loading}
          onRow={(data: DocumentType) => ({
            onDoubleClick: () => entityPage(data.id),
          })}
          rowKey={'id'}
          columns={columnsWithActions}
          dataSource={items}
          pagination={false}
          footer={
            collection.length === limit
              ? () => (
                  <div className="list-footer">
                    <Button
                      onClick={() => setLimit(limit + 5)}
                      type="ghost"
                      loading={loading}
                    >
                      <small>Charger plus ...</small>
                    </Button>
                  </div>
                )
              : undefined
          }
          scroll={scroll}
        />
      </div>
      <div className={'table-content'}>
        {action === 'new' && (
          <Modal onCancel={basePage}>
            {renderCreate && renderCreate(basePage)}
          </Modal>
        )}
        <Switch>
          {rowOpsViews && rowOpsViews(selectedItem)}
          <ErrorBoundaryRoute
            // Using wildcard is necessary to make the edit button work on item page.
            path={`${path}/${collectionName}/*/edit`}
            exact
            render={() =>
              selectedItem && (
                <Modal onCancel={basePage}>
                  {renderEdit && renderEdit(selectedItem, basePage)}
                </Modal>
              )
            }
          />
          <ErrorBoundaryRoute
            path={`${path}/${collectionName}/${documentId}`}
            render={() =>
              selectedItem && (
                <Modal onCancel={basePage}>
                  {renderDetails &&
                    renderDetails(selectedItem, () => entityEdit(documentId))}
                </Modal>
              )
            }
          />
        </Switch>
      </div>
    </div>
  );
};
