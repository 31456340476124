import { registerEntity } from '../../../config/firebase/firestore/register-entity';
import {
  userCollectionName,
  userCollectionRef,
  userDocumentRef,
} from '../firebase/user.store';
import { CollectionReadParams } from '../../../redux/actions/utils/generate-collection-actions';
import { User } from '../user.domain';
import { Observable, of } from 'rxjs';
import { AnyAction } from 'typescript-fsa';
import { ofAction } from '../../../redux/epics/utils/of-action';
import { ApplicationActions } from '../../../redux/actions/application.actions';
import { mergeMap, startWith } from 'rxjs/operators';

export interface UserCollectionReadParams extends CollectionReadParams<User> {}

export const UserRegister = registerEntity(
  'user',
  userCollectionName,
  () => userCollectionRef(),
  ['firstName', 'lastName'],
  user => {
    return userDocumentRef(user.id);
  }
);

export const userInitEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofAction(ApplicationActions.start),
    mergeMap(action =>
      of(
        UserRegister.documentActions.subscribe.started({
          id: action.payload,
        })
      ).pipe(startWith(UserRegister.documentActions.unsubscribe()))
    )
  );
