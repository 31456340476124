import React from 'react';
import { FormEditComponent } from '../form/form-edit.component';
import { FileForm } from './file-form';
import { File } from '../../domain/file/file.domain';
import { FileRegister } from '../../domain/file/redux/file-register';

export interface FileEditFormProps {
  title?: string;
  file: File;
  onDone?: (documentId: string) => void;
}

export const FileEditFormComponent: React.FC<FileEditFormProps> = ({
  title = 'Fichier',
  file,
  onDone,
}) => (
  <FormEditComponent
    title={title}
    subTitle={'Éditer'}
    documentStateSelector={FileRegister.documentSelectors}
    updateActionCreator={FileRegister.documentActions.update}
    cleanActionCreator={FileRegister.documentActions.cleanState}
    document={file}
    onDone={onDone}
    children={(file, form) => <FileForm file={file} form={form} />}
  />
);
