import React from 'react';
import { FormCreateComponent } from '../form/form-create.component';
import { emptyObject } from '../../utils/constants';
import { Project } from '../../domain/project/project.domain';
import { ProjectRegister } from '../../domain/project/redux/project-register';
import { ProjectForm } from './project-form';

export interface ProjectCreateFormProps {
  title?: string;
  agenceId?: string;
  onDone?: (documentId: string) => void;
  initialValues?: Partial<Project>;
}

export const ProjectCreateFormComponent: React.FC<ProjectCreateFormProps> = ({
  title = 'Projet',
  agenceId,
  onDone,
  initialValues = emptyObject<Partial<Project>>(),
}) => (
  <FormCreateComponent
    title={title}
    subTitle={'Créer'}
    documentStateSelector={ProjectRegister.documentSelectors}
    writeActionCreator={ProjectRegister.documentActions.write}
    cleanActionCreator={ProjectRegister.documentActions.cleanState}
    initialValues={{ agenceId, ...initialValues }}
    onDone={onDone}
    children={(newProjectId, form) => (
      <ProjectForm
        newProjectId={newProjectId}
        agenceId={agenceId}
        form={form}
      />
    )}
  />
);
