import { message } from 'antd';
import { emptyArray } from '../utils/constants';
import { computeTva } from './tva';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import { CompanyForm } from '../domain/enum/company-form';

/**
 * Response Example search by siren :
 *
 {
  "sirene": {
    "data": {
      "total_results": 1,
      "siege_social": {
        "id": 133083019,
        "siren": "817732936",
        "siret": "81773293600015",
        "nic": "00015",
        "l1_normalisee": "ZSOFT CONSULTING",
        "l2_normalisee": "3",
        "l3_normalisee": null,
        "l4_normalisee": "3 BIS RUE TAYLOR",
        "l5_normalisee": null,
        "l6_normalisee": "75010 PARIS 10",
        "l7_normalisee": null,
        "l1_declaree": null,
        "l2_declaree": null,
        "l3_declaree": null,
        "l4_declaree": "3 BIS RUE TAYLOR",
        "l5_declaree": null,
        "l6_declaree": "75010 PARIS 10",
        "l7_declaree": null,
        "numero_voie": "3",
        "indice_repetition": "B",
        "type_voie": "RUE",
        "libelle_voie": "TAYLOR",
        "code_postal": "75010",
        "cedex": null,
        "region": "11",
        "libelle_region": null,
        "departement": "75",
        "arrondissement": "1",
        "canton": null,
        "commune": "110",
        "libelle_commune": "PARIS 10E ARRONDISSEMENT",
        "departement_unite_urbaine": null,
        "taille_unite_urbaine": null,
        "numero_unite_urbaine": null,
        "etablissement_public_cooperation_intercommunale": null,
        "tranche_commune_detaillee": "80",
        "zone_emploi": null,
        "is_siege": "1",
        "enseigne": null,
        "indicateur_champ_publipostage": "A",
        "statut_prospection": null,
        "date_introduction_base_diffusion": "201603",
        "nature_entrepreneur_individuel": null,
        "libelle_nature_entrepreneur_individuel": null,
        "activite_principale": "6202A",
        "libelle_activite_principale": "Conseil en systèmes et logiciels informatiques",
        "date_validite_activite_principale": null,
        "tranche_effectif_salarie": "02",
        "libelle_tranche_effectif_salarie": null,
        "tranche_effectif_salarie_centaine_pret": null,
        "date_validite_effectif_salarie": "2017",
        "origine_creation": null,
        "date_creation": "20151230",
        "date_debut_activite": "20160309",
        "nature_activite": null,
        "lieu_activite": null,
        "type_magasin": null,
        "is_saisonnier": null,
        "modalite_activite_principale": null,
        "caractere_productif": null,
        "participation_particuliere_production": null,
        "caractere_auxiliaire": null,
        "nom_raison_sociale": "ZSOFT CONSULTING",
        "sigle": null,
        "nom": null,
        "prenom": null,
        "civilite": null,
        "numero_rna": null,
        "nic_siege": "00015",
        "region_siege": "11",
        "departement_commune_siege": "75110",
        "email": null,
        "nature_juridique_entreprise": "5710",
        "libelle_nature_juridique_entreprise": "SAS, société par actions simplifiée",
        "activite_principale_entreprise": "6202A",
        "libelle_activite_principale_entreprise": "Conseil en systèmes et logiciels informatiques",
        "date_validite_activite_principale_entreprise": null,
        "activite_principale_registre_metier": null,
        "is_ess": null,
        "date_ess": null,
        "tranche_effectif_salarie_entreprise": "02",
        "libelle_tranche_effectif_salarie_entreprise": null,
        "tranche_effectif_salarie_entreprise_centaine_pret": null,
        "date_validite_effectif_salarie_entreprise": "2017",
        "categorie_entreprise": "PME",
        "date_creation_entreprise": "201512",
        "date_introduction_base_diffusion_entreprise": "201603",
        "indice_monoactivite_entreprise": null,
        "modalite_activite_principale_entreprise": null,
        "caractere_productif_entreprise": null,
        "date_validite_rubrique_niveau_entreprise_esa": null,
        "tranche_chiffre_affaire_entreprise_esa": null,
        "activite_principale_entreprise_esa": null,
        "premiere_activite_secondaire_entreprise_esa": null,
        "deuxieme_activite_secondaire_entreprise_esa": null,
        "troisieme_activite_secondaire_entreprise_esa": null,
        "quatrieme_activite_secondaire_entreprise_esa": null,
        "nature_mise_a_jour": null,
        "indicateur_mise_a_jour_1": null,
        "indicateur_mise_a_jour_2": null,
        "indicateur_mise_a_jour_3": null,
        "date_mise_a_jour": "2019-06-24T14:03:28",
        "created_at": "2020-02-16T13:10:35.000+01:00",
        "updated_at": "2020-02-16T13:10:35.000+01:00",
        "geo_l4": "3 BIS RUE TAYLOR",
        "geo_l5": null
      },
      "other_etablissements_sirets": []
    },
    "status": 200,
    "metadata": {
      "id": "SIRENE",
      "producteur": "INSEE",
      "nature": "Fichier Stock",
      "adress": "https://www.data.gouv.fr/en/datasets/base-sirene-des-entreprises-et-de-leurs-etablissements-siren-siret/",
      "date_creation_stock": "2020-02"
    }
  },
  "repertoire_national_metiers": {
    "api_http_link": "https://api-rnm.artisanat.fr/api/entreprise/817732936",
    "metadata": {
      "id": "Répertoire National des Métiers",
      "producteur": "Chambre de Métiers et de l'Artisanat",
      "nature": "API",
      "adress": "https://api-rnm.artisanat.fr/"
    }
  },
  "computed": {
    "data": {
      "numero_tva_intra": "FR47817732936",
      "geo": {
        "longitude": "2.35898",
        "latitude": "48.869458",
        "geo_score": "0.51",
        "geo_type": "housenumber",
        "geo_adresse": "3 bis Rue Taylor 75010 Paris",
        "geo_id": "75110_9182_00003_bis",
        "geo_ligne": "G"
      }
    },
    "metadata": {
      "numero_tva_intra": {
        "origin": "Computed by formula: 'FR' + '(12 + 3 * (siren % 97)) % 97' + 'siren'"
      },
      "geo": {
        "origin": "Computed from SIRENE adresses via additional sources database",
        "sources": "Base Nationale Adresse (Data.gouv), Base Nationale Adresse Ouverte (OpenStreetMap France), Interest Points OpenStreetMap"
      }
    }
  }
}
 */

export const searchCompanyBySiren = async (
  siren: string
): Promise<Omit<{/*Company*/}, 'id'> | undefined> => {
  if (!siren || siren.length !== 9) {
    return;
  }
  try {
    const response = await fetch(
      `https://entreprise.data.gouv.fr/api/sirene/v2/siren/${siren}`
    );
    const jsonResponse = await response.json();
    return parseSearchBySirenResponse(jsonResponse);
  } catch (error) {
    message.error("Erreur lors de la récupération de l'entreprise par siren");
    console.error(error);
    return;
  }
};

export const fromCompanyJson = (companyJson: any) => {
  let form = CompanyForm.OTHER;
  if (companyJson.libelle_nature_juridique_entreprise)
    if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        'Société civile immobilière'
      )
    ) {
      form = CompanyForm.SCI;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        "SA à conseil d'administration"
      )
    ) {
      form = CompanyForm.SA;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        'Société à responsabilité limitée'
      )
    ) {
      form = CompanyForm.SARL;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes('SAS')
    ) {
      form = CompanyForm.SAS;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        'SARL unipersonnelle'
      )
    ) {
      form = CompanyForm.EURL;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        'Société par actions simplifiée à associé unique'
      )
    ) {
      form = CompanyForm.SASU;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        'libéral à responsabilité limitée'
      )
    ) {
      form = CompanyForm.SELARL;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        'libéral à forme anonyme'
      )
    ) {
      form = CompanyForm.SELAFA;
    } else if (
      companyJson.libelle_nature_juridique_entreprise.includes(
        'libéral en commandite par actions'
      )
    ) {
      form = CompanyForm.SELCA;
    }
  return {
    name: startCase(lowerCase(companyJson.nom_raison_sociale)),
    siren: companyJson.siren,
    siret: companyJson.siret,
    form,
    address: {
      addressLine1: startCase(
        lowerCase(
          `${companyJson.numero_voie || ''} ${
            companyJson.indice_repetition || ''
          } ${companyJson.type_voie || ''} ${companyJson.libelle_voie || ''}`
        )
      ),
      city: startCase(lowerCase(companyJson.libelle_commune ?? '')),
      postalCode: companyJson.code_postal ?? '',
      country: 'France',
    },
    tva: computeTva(companyJson.siren),
    nafCode: companyJson.activite_principale_entreprise,
  };
};

export const parseSearchBySirenResponse = (
  json: any
): Omit<{/*Company*/}, 'id'> | undefined => {
  if (
    json &&
    json.sirene &&
    json.sirene.data &&
    json.sirene.data.siege_social &&
    json.sirene.data.total_results > 0
  ) {
    const companyJson = json.sirene.data.siege_social;
    return fromCompanyJson(companyJson);
  }
};

/**
 * Response Example search by name :
 {
  "total_results": 6,
  "total_pages": 1,
  "per_page": 10,
  "page": 1,
  "etablissement": [
    {
      "id": 136104383,
      "siren": "753840719",
      "siret": "75384071900029",
      "nic": "00029",
      "l1_normalisee": "GLOBAL INCENDIE",
      "l2_normalisee": null,
      "l3_normalisee": null,
      "l4_normalisee": "18 BIS RUE MAURICE RECHSTEINER",
      "l5_normalisee": null,
      "l6_normalisee": "95100 ARGENTEUIL",
      "l7_normalisee": null,
      "l1_declaree": null,
      "l2_declaree": null,
      "l3_declaree": null,
      "l4_declaree": "18 BIS RUE MAURICE RECHSTEINER",
      "l5_declaree": null,
      "l6_declaree": "95100 ARGENTEUIL",
      "l7_declaree": null,
      "numero_voie": "18",
      "indice_repetition": "B",
      "type_voie": "RUE",
      "libelle_voie": "MAURICE RECHSTEINER",
      "code_postal": "95100",
      "cedex": null,
      "region": "11",
      "libelle_region": "Île-de-France",
      "departement": "95",
      "arrondissement": "1",
      "canton": "98",
      "commune": "018",
      "libelle_commune": "ARGENTEUIL",
      "departement_unite_urbaine": "95",
      "taille_unite_urbaine": "8",
      "numero_unite_urbaine": "00851",
      "etablissement_public_cooperation_intercommunale": "200054781",
      "tranche_commune_detaillee": "61",
      "zone_emploi": "1101",
      "is_siege": "1",
      "enseigne": null,
      "indicateur_champ_publipostage": "A",
      "statut_prospection": null,
      "date_introduction_base_diffusion": "201703",
      "nature_entrepreneur_individuel": null,
      "libelle_nature_entrepreneur_individuel": null,
      "activite_principale": "4759B",
      "libelle_activite_principale": "Commerce de détail d'autres équipements du foyer",
      "date_validite_activite_principale": null,
      "tranche_effectif_salarie": "01",
      "libelle_tranche_effectif_salarie": null,
      "tranche_effectif_salarie_centaine_pret": null,
      "date_validite_effectif_salarie": "2017",
      "origine_creation": null,
      "date_creation": "20170310",
      "date_debut_activite": "20170310",
      "nature_activite": null,
      "lieu_activite": null,
      "type_magasin": null,
      "is_saisonnier": null,
      "modalite_activite_principale": null,
      "caractere_productif": null,
      "participation_particuliere_production": null,
      "caractere_auxiliaire": null,
      "nom_raison_sociale": "GLOBAL INCENDIE",
      "sigle": null,
      "nom": null,
      "prenom": null,
      "civilite": null,
      "numero_rna": null,
      "nic_siege": "00029",
      "region_siege": "11",
      "departement_commune_siege": "95018",
      "email": null,
      "nature_juridique_entreprise": "5720",
      "libelle_nature_juridique_entreprise": "Société par actions simplifiée à associé unique ou société par actions simplifiée unipersonnelle",
      "activite_principale_entreprise": "4759B",
      "libelle_activite_principale_entreprise": "Commerce de détail d'autres équipements du foyer",
      "date_validite_activite_principale_entreprise": null,
      "activite_principale_registre_metier": null,
      "is_ess": null,
      "date_ess": null,
      "tranche_effectif_salarie_entreprise": "01",
      "libelle_tranche_effectif_salarie_entreprise": null,
      "tranche_effectif_salarie_entreprise_centaine_pret": null,
      "date_validite_effectif_salarie_entreprise": "2017",
      "categorie_entreprise": "PME",
      "date_creation_entreprise": "201209",
      "date_introduction_base_diffusion_entreprise": "201703",
      "indice_monoactivite_entreprise": null,
      "modalite_activite_principale_entreprise": null,
      "caractere_productif_entreprise": null,
      "date_validite_rubrique_niveau_entreprise_esa": null,
      "tranche_chiffre_affaire_entreprise_esa": null,
      "activite_principale_entreprise_esa": null,
      "premiere_activite_secondaire_entreprise_esa": null,
      "deuxieme_activite_secondaire_entreprise_esa": null,
      "troisieme_activite_secondaire_entreprise_esa": null,
      "quatrieme_activite_secondaire_entreprise_esa": null,
      "nature_mise_a_jour": null,
      "indicateur_mise_a_jour_1": null,
      "indicateur_mise_a_jour_2": null,
      "indicateur_mise_a_jour_3": null,
      "date_mise_a_jour": "2019-06-24T13:44:08",
      "created_at": "2020-02-16T14:01:24.000+01:00",
      "updated_at": "2020-02-16T14:01:24.000+01:00",
      "longitude": "2.245004",
      "latitude": "48.94672",
      "geo_score": "0.84",
      "geo_type": "housenumber",
      "geo_adresse": "18 BIS Rue Maurice Rechsteiner 95100 Argenteuil",
      "geo_id": "ADRNIVX_0000000350478169",
      "geo_ligne": "G",
      "geo_l4": "18 BIS RUE MAURICE RECHSTEINER",
      "geo_l5": null
    },
  ],
  "spellcheck": null,
  "suggestions": [
    "global incendie"
  ]
}
 */
export const searchCompanyByName = async (name: string): Promise<{/*Company*/}[]> => {
  if (name) {
    try {
      const response = await fetch(
        `https://entreprise.data.gouv.fr/api/sirene/v1/full_text/${name}`
      );
      const jsonResponse = await response.json();
      return parseSearchByNameResponse(jsonResponse);
    } catch (error) {
      message.error("Erreur lors de la récupération de l'entreprise par nom");
    }
  }
  return emptyArray();
};

export const parseSearchByNameResponse = (json: any): {/*Company*/}[] => {
  if (
    json &&
    json.etablissement &&
    json.total_results > 0 &&
    json.etablissement.length > 0
  ) {
    return json.etablissement.map(fromCompanyJson);
  }
  return emptyArray();
};
