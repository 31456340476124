import React from 'react';
import {
  AgenceCollectionReadParams,
  AgenceRegister,
} from '../../domain/agence/redux/agence-register';
import { TableDisplayComponent } from '../table/table-display.component';
import { AgenceCreateFormComponent } from './agence-create-form.component';
import { AgenceEditFormComponent } from './agence-edit-form.component';
import { agenceCollectionName } from '../../domain/agence/firebase/agence.store';
import { AgenceColumns } from './agence-columns';

export interface AgenceTableProps {
  agenceId?: string;
  search?: boolean;
}

export const AgenceTableComponent: React.FC<AgenceTableProps> = ({
  agenceId,
  search,
}) => {
  const readParams = React.useMemo<AgenceCollectionReadParams>(() => ({}), []);

  return (
    <TableDisplayComponent
      title={'Agences'}
      entityName={'agence'}
      search={true}
      collectionName={agenceCollectionName}
      columns={AgenceColumns}
      readParams={readParams}
      documentActions={AgenceRegister.documentActions}
      collectionActions={AgenceRegister.collectionActions}
      collectionStateSelector={AgenceRegister.collectionSelectors}
      renderCreate={onDone => <AgenceCreateFormComponent onDone={onDone} />}
      renderEdit={(agence, onDone) => (
        <AgenceEditFormComponent agence={agence} onDone={onDone} />
      )}
      renderDetails={agence => <AgenceEditFormComponent agence={agence} />}
    />
  );
};
