import React from 'react';
import { Pagination, Spin } from 'antd';
// @ts-ignore
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './pdf-viewer.less';

export interface DocumentViewerProps extends React.HTMLAttributes<HTMLElement> {
  loading?: boolean;
  file: any;
}

export const PDFViewer: React.FC<DocumentViewerProps> = ({ file, style }) => {
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberOfPages, setNumberOfPages] = React.useState(1);

  const onLoadSuccess = React.useCallback(({ numPages }) => {
    setNumberOfPages(numPages);
    setCurrentPage(1);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    setCurrentPage(1);
    setNumberOfPages(1);
  }, [file]);

  return (
    <Spin spinning={loading}>
      {file && (
        <div className={'wrapper'} style={style}>
          <Document
            className={'document'}
            renderMode={'canvas'}
            file={file}
            onLoadSuccess={onLoadSuccess}
          >
            <Page pageNumber={currentPage} />
          </Document>
          {numberOfPages && (
            <Pagination
              className={'pagination'}
              simple
              onChange={setCurrentPage}
              total={numberOfPages}
              pageSize={1}
            />
          )}
        </div>
      )}
    </Spin>
  );
};
