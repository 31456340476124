import React from 'react';
import { FormInstance } from 'antd/es/form';
import { Form } from 'antd';
import { PicturesUploadComponent } from '../form/components/pictures-upload.component';
import { EmailField } from '../form/field/email-field.component';
import { AddressField } from '../form/field/address-field.component';
import { PhoneNumberField } from '../form/field/phone-number-field.component';
import { getAgenceLogoRef } from '../../domain/agence/firebase/agence.storage';
import { Agence } from '../../domain/agence/agence.domain';
import { CompanyNameField } from '../form/field/company-name-field.component';

interface AgenceFormProps {
  newAgenceId?: string;
  agence?: Agence;
  form?: FormInstance;
}

export const AgenceForm: React.FC<AgenceFormProps> = ({
  newAgenceId,
  agence,
  form,
}) => {
  const agenceId = newAgenceId ?? agence?.id ?? '';

  return (
    <>
      <CompanyNameField
        autoFocus
        required
        search
        form={form}
        label="Raison Sociale de l'agence"
      />
      <Form.Item name="logoUrl" label={'Logo :'}>
        <PicturesUploadComponent
          crop={false}
          fileSizeLimit={100 * 1024}
          numberOfPictures={1}
          fileRef={getAgenceLogoRef(agenceId)}
        />
      </Form.Item>
      <EmailField required />
      <PhoneNumberField />
      <AddressField label={'Adresse'} form={form} required />
    </>
  );
};
