import React from 'react';
import { Alert, Upload } from 'antd';
import firebase from 'firebase/app';
import { UploadFile, RcFile } from 'antd/es/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { PlusOutlined } from '@ant-design/icons';
import { emptyArray } from '../../../utils/constants';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import imageCompression from 'browser-image-compression';
import ImgCrop from 'antd-img-crop';
import { ConditionalWrapper } from '../../utils/conditional-wrapper';
import { isMobile } from 'react-device-detect';
import { Modal } from '../../utils/modal';

interface PicturesUploadComponentProps {
  fileRef?: firebase.storage.Reference;
  value?: string | string[];
  onChange?: (value?: string | string[]) => void;
  disabled?: boolean;
  fileSizeLimit?: number;
  numberOfPictures?: number;
  crop?: boolean;
}

export const PicturesUploadComponent = ({
  fileRef,
  value,
  onChange,
  disabled = false,
  fileSizeLimit = 15 * 1024 * 1024,
  numberOfPictures = 3,
  crop = true,
}: PicturesUploadComponentProps) => {
  const [values, setValues] = React.useState<string[]>(emptyArray());
  const [alert, setAlert] = React.useState<string>();
  const [fileList, setFileList] = React.useState<UploadFile[]>(emptyArray());
  const [preview, setPreview] = React.useState<string>();

  React.useEffect(() => {
    if (isArray(value)) {
      setValues(value);
    } else if (isString(value)) {
      setValues([value]);
    } else {
      setValues(emptyArray());
    }
  }, [value]);

  React.useEffect(() => {
    setFileList(
      values.map((url, index) => ({
        size: 0,
        uid: `${index}`,
        name: `picture-${index}`,
        status: 'done',
        type: 'image/jpg',
        url,
        originFileObj: {} as any,
      }))
    );
  }, [values]);

  const handleUpload = React.useCallback(
    async (options: UploadRequestOption) => {
      if (!fileRef || !onChange) {
        return;
      }
      let file = options.file as RcFile;
      const metadata = {
        contentType: file.type,
        size: file.size,
      };
      let blob;
      if (file.size > fileSizeLimit) {
        blob = await imageCompression(file, {
          maxSizeMB: fileSizeLimit / 1024,
          maxWidthOrHeight: 800,
        });
      }
      const uploadRef = fileRef.child(`${values?.length}`);
      const uploadTask = uploadRef.put(blob ?? file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
        next: snapshot =>
          options.onProgress &&
          options.onProgress({
            percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          } as any),
        error: error => options.onError && options.onError(error),
        complete: async () => {
          options.onSuccess && options.onSuccess({}, {} as any);
          const url = await uploadRef.getDownloadURL();
          if (numberOfPictures > 1) {
            onChange([...values, url]);
          } else {
            onChange(url);
          }
        },
      });
    },
    [fileRef, fileSizeLimit, numberOfPictures, onChange, values]
  );

  const beforeUpload = React.useCallback(
    (file: File) => {
      if (!fileRef) {
        setAlert('Une erreur est survenue !');
        return false;
      }
      setAlert(undefined);
      return true;
    },
    [fileRef]
  );

  return (
    <>
      <ConditionalWrapper
        wrap={!isMobile && crop}
        wrapper={children => (
          <ImgCrop
            modalTitle={'Éditer'}
            minZoom={0}
            rotate={true}
            zoom={true}
            children={children}
          />
        )}
      >
        <Upload
          disabled={disabled}
          customRequest={handleUpload}
          accept="image/gif, image/jpg, image/jpeg, image/png"
          name="file-upload"
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onPreview={file => setPreview(file.url)}
          onRemove={file =>
            onChange &&
            onChange(
              numberOfPictures === 1
                ? undefined
                : values?.filter(url => file.url !== url)
            )
          }
        >
          {fileList.length >= numberOfPictures || disabled ? null : (
            <div>
              <PlusOutlined />
            </div>
          )}
        </Upload>
      </ConditionalWrapper>
      {alert && <Alert type="error" message={alert} />}
      <Modal visible={!!preview} onCancel={() => setPreview(undefined)}>
        <img alt="preview" style={{ width: '100%' }} src={preview} />
      </Modal>
    </>
  );
};
