export enum CompanyForm {
  SA = "SA",
  SCI = "SCI",
  SARL = "SARL",
  EURL = "EURL",
  SAS = "SAS",
  SASU = "SASU",
  SELARL = "SELARL",
  SELAFA = "SELAFA",
  SELAS = "SELAS",
  SELCA = "SELCA",
  OTHER = " ",
}
