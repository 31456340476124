import React from 'react';
import { useQuery } from './useQuery';
import { Properties } from '../../utils/types';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const formatParams = (params: Properties<string>) =>
  Object.entries(params).reduce(
    (result, [key, value]) =>
      result ? `${result}&${key}=${value}` : `?${key}=${value}`,
    ''
  );

export const formatParam = (value: Properties) =>
  encodeURIComponent(JSON.stringify(value));

export const parseParam = (value: string | null) => {
  try {
    return value ? JSON.parse(decodeURIComponent(value)) : undefined;
  } catch (error) {
    return value;
  }
};

export const useParams = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const query = useQuery();

  const setParams = React.useCallback(
    (params: Properties<string>) =>
      dispatch(push(pathname + formatParams(params))),
    [dispatch, pathname]
  );
  const params: Properties = {};
  query.forEach((value, key) => {
    params[key] = parseParam(value);
  });
  return { params, setParams };
};
