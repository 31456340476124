import React from 'react';
import { Form, Input } from 'antd';

interface EmailFieldProps {
  name?: string;
  defaultValue?: string;
  label?: string;
  noLabel?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  noStyle?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  help?: string;
  validateStatus?:
    | ''
    | 'success'
    | 'warning'
    | 'error'
    | 'validating'
    | undefined;
}

export const EmailField: React.FC<EmailFieldProps> = ({
  name = 'email',
  defaultValue,
  label = 'Addresse Email',
  noLabel = false,
  required = false,
  autoFocus = false,
  noStyle = false,
  disabled = false,
  style,
  help,
  validateStatus,
}) => {
  return (
    <Form.Item
      name={name}
      label={noLabel ? undefined : label}
      rules={[
        {
          required,
        },
        { type: 'email' },
      ]}
      noStyle={noStyle}
      help={help}
      validateStatus={validateStatus}
      normalize={value => value?.toLowerCase()}
    >
      <Input
        defaultValue={defaultValue}
        placeholder="Email"
        inputMode={'email'}
        autoFocus={autoFocus}
        disabled={disabled}
        style={style}
      />
    </Form.Item>
  );
};
