import React from 'react';
import { FormCreateComponent } from '../form/form-create.component';
import { emptyObject } from '../../utils/constants';
import { AgenceRegister } from '../../domain/agence/redux/agence-register';
import { Agence } from '../../domain/agence/agence.domain';
import { AgenceForm } from './agence-form';

export interface AgenceCreateFormProps {
  title?: string;
  onDone?: (documentId: string) => void;
  initialValues?: Partial<Agence>;
}

export const AgenceCreateFormComponent: React.FC<AgenceCreateFormProps> = ({
  title = 'Agence',
  onDone,
  initialValues = emptyObject<Partial<Agence>>(),
}) => (
  <FormCreateComponent
    title={title}
    subTitle={'Créer'}
    initialValues={initialValues}
    documentStateSelector={AgenceRegister.documentSelectors}
    writeActionCreator={AgenceRegister.documentActions.write}
    cleanActionCreator={AgenceRegister.documentActions.cleanState}
    onDone={onDone}
    children={(newAgenceId, form) => (
      <AgenceForm newAgenceId={newAgenceId} form={form} />
    )}
  />
);
