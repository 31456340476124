import React from 'react';
import { FormCreateComponent } from '../form/form-create.component';
import { emptyObject } from '../../utils/constants';
import { File } from '../../domain/file/file.domain';
import { FileRegister } from '../../domain/file/redux/file-register';
import { FileForm } from './file-form';

export interface FileCreateFormProps {
  title?: string;
  agenceId?: string;
  projectId?: string;
  onDone?: (documentId: string) => void;
  initialValues?: Partial<File>;
}

export const FileCreateFormComponent: React.FC<FileCreateFormProps> = ({
  title = 'Fichier',
  agenceId,
  projectId,
  onDone,
  initialValues = emptyObject<Partial<File>>(),
}) => (
  <FormCreateComponent
    title={title}
    subTitle={'Créer'}
    documentStateSelector={FileRegister.documentSelectors}
    writeActionCreator={FileRegister.documentActions.write}
    cleanActionCreator={FileRegister.documentActions.cleanState}
    initialValues={{ agenceId, projectId, ...initialValues }}
    onDone={onDone}
    children={(newFileId, form) => (
      <FileForm
        newFileId={newFileId}
        agenceId={agenceId}
        projectId={projectId}
        form={form}
      />
    )}
  />
);
