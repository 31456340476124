import React from 'react';
import { FormEditComponent } from '../form/form-edit.component';
import { AgenceRegister } from '../../domain/agence/redux/agence-register';
import { Agence } from '../../domain/agence/agence.domain';
import { AgenceForm } from './agence-form';

export interface AgenceEditFormProps {
  title?: string;
  agence: Agence;
  onDone?: (documentId: string) => void;
}

export const AgenceEditFormComponent: React.FC<AgenceEditFormProps> = ({
  title = 'Agence',
  agence,
  onDone,
}) => (
  <FormEditComponent
    title={title}
    subTitle={'Éditer'}
    documentStateSelector={AgenceRegister.documentSelectors}
    updateActionCreator={AgenceRegister.documentActions.update}
    cleanActionCreator={AgenceRegister.documentActions.cleanState}
    document={agence}
    onDone={onDone}
    children={(agence, form) => <AgenceForm agence={agence} form={form} />}
  />
);
