import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/analytics';
import 'firebase/performance';

const getVariable = (env: string) => {
  return process.env[
    `${env}${process.env.REACT_APP_STAGE === 'production' ? '_PRODUCTION' : ''}`
  ];
};

const options = {
  apiKey: getVariable('REACT_APP_FIREBASE_API_KEY'),
  authDomain: getVariable('REACT_APP_FIREBASE_AUTH_DOMAIN'),
  databaseURL: getVariable('REACT_APP_FIREBASE_DATABASE_URL'),
  projectId: getVariable('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getVariable('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getVariable('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
  appId: getVariable('REACT_APP_FIREBASE_APP_ID'),
  measurementId: getVariable('REACT_APP_FIREBASE_MEASUREMENT_ID'),
};

const firebaseApp = firebase.initializeApp(options);

// Configure auth language
firebaseApp.auth().useDeviceLanguage();

export const auth = firebaseApp.auth();

export const database = firebaseApp.database();

export const firestore = firebaseApp.firestore();
firestore.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});
firestore.enablePersistence({ synchronizeTabs: true }).catch(console.error);

//TODO add prompt to ask user if it's trusted device

export const analytics = firebaseApp.analytics();

export const storage = firebaseApp.storage();

export const performance = firebaseApp.performance();

export const functions = firebaseApp.functions('europe-west3');
