import React from 'react';
import { AutoComplete, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { EnvironmentOutlined } from '@ant-design/icons';
import { searchAddress } from '../../../helpers/address';
import debounce from 'lodash/debounce';
import { Address } from '../../../domain/types/address';
import { emptyArray } from '../../../utils/constants';

const renderOption = (address: Address, index: number) => ({
  address,
  value: `${index}`,
  key: `${index}`,
  label: (
    <div>
      <EnvironmentOutlined />
      <small style={{ margin: '0 1rem' }}>
        {address.addressLine1}, {address.city}, {address.country}
      </small>
    </div>
  ),
});

export interface AddressFieldProps {
  label?: string;
  addressKey?: string;
  required?: boolean;
  search?: boolean;
  form?: FormInstance;
  onChange?: (address: Address) => void;
}

export const AddressField: React.FC<AddressFieldProps> = ({
  label = 'Adresse postale',
  addressKey = 'address',
  required = false,
  search = true,
  form,
  onChange,
}) => {
  const name = addressKey ? [addressKey] : [];
  const [addressList, setAddressList] = React.useState<Address[]>(emptyArray());

  const onSearch = debounce(
    async value => setAddressList(await searchAddress(value)),
    500
  );

  const onSelect = (value: string, option: any) => {
    form?.setFieldsValue({ [addressKey]: option.address });
    onChange && onChange(option.address);
  };

  return (
    <Form.Item label={label} required={required}>
      <Form.Item
        noStyle
        name={name.concat('addressLine1')}
        rules={[
          {
            required: true,
            message: "L'adresse est requise !",
          },
        ]}
      >
        {search ? (
          <AutoComplete
            options={addressList.map(renderOption)}
            onSelect={onSelect}
            onSearch={onSearch}
          >
            <Input.Search
              inputMode={'search'}
              placeholder="Saisir une adresse ..."
            />
          </AutoComplete>
        ) : (
          <Input placeholder="Ligne 1: 1 rue ..." />
        )}
      </Form.Item>
      <Form.Item noStyle name={name.concat('addressLine2')}>
        <Input
          style={{ width: '100%' }}
          placeholder="Ligne 2: Bureau, entrée ..."
        />
      </Form.Item>

      <Input.Group>
        <Form.Item
          noStyle
          name={name.concat('postalCode')}
          rules={[
            {
              required: true,
              message: 'Le code postal est requis !',
            },
          ]}
          normalize={value => value.replace(/\D/g, '')}
        >
          <Input
            maxLength={5}
            style={{ width: '30%' }}
            placeholder="Code Postal"
          />
        </Form.Item>
        <Form.Item
          noStyle
          name={name.concat('city')}
          rules={[
            {
              required: true,
              message: 'La ville est requise !',
            },
          ]}
        >
          <Input style={{ width: '35%' }} placeholder="Ville" />
        </Form.Item>
        <Form.Item
          name={name.concat('country')}
          noStyle
          rules={[{ required: true, message: 'Le Pays est requis !' }]}
        >
          <Select
            placeholder="Pays"
            defaultActiveFirstOption
            style={{ width: '35%' }}
          >
            <Select.Option value="France" label="France">
              <div>
                <span role="img" aria-label="France">
                  🇫🇷
                </span>
                France
              </div>
            </Select.Option>
          </Select>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};
