import actionCreatorFactory from 'typescript-fsa';
import firebase from 'firebase/app';

const RtDatabaseActionTypes = {
  READ: 'READ',
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  CLEAN_STATE: 'CLEAN_STATE',
  RESET_STATE: 'RESET_STATE',
};

const factory = actionCreatorFactory('DATABASE');

export const DatabaseActions = {
  read: factory.async<string, firebase.database.DataSnapshot, Error>(
    RtDatabaseActionTypes.READ
  ),
  subscribe: factory.async<string, void, Error>(RtDatabaseActionTypes.SUBSCRIBE),
  unsubscribe: factory<string>(RtDatabaseActionTypes.UNSUBSCRIBE),
  cleanState: factory<string>(RtDatabaseActionTypes.CLEAN_STATE),
  resetState: factory<string>(RtDatabaseActionTypes.RESET_STATE),
};
