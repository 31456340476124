import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Layout } from './layout/layout.component';
import { Home } from './home';
import { ErrorBoundaryRoute } from './router/error-boundary-route';
import { Auth } from './auth/auth.component';
import { PageLoading } from './utils/page-loading.component';
import { appSelector } from '../redux/selectors/app.selector';
import { ProjectList } from './project/project-list.component';
import { AgenceTableComponent } from './agence/agence-table.component';
import { useRole } from '../hooks/use-role.hook';
import { PrivateRoute } from './router/private-route';

export const Application = () => {
  const appState = useSelector(appSelector);

  const { role } = useRole();

  if (appState.loading) {
    return <PageLoading loadingMessage="Chargement ..." />;
  }

  return (
    <Layout>
      <Switch>
        <ErrorBoundaryRoute path="/auth" children={<Auth />} />
        {role === 'admin' ? (
          <>
            <PrivateRoute path="/projects" children={<ProjectList />} />
            <PrivateRoute path="/agences" children={<AgenceTableComponent />} />
          </>
        ) : (
          <>
            <PrivateRoute path="/projects" children={<ProjectList />} />
            <PrivateRoute path="/agences" children={<AgenceTableComponent />} />
          </>
        )}
        <PrivateRoute children={<Home />} />
      </Switch>
    </Layout>
  );
};
