import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { TitleWithAvatar } from '../ui/avatar/title-with-avatar.component';
import { Agence } from '../../domain/agence/agence.domain';

export const AgenceColumns: ColumnsType<Agence> = [
  {
    title: 'Nom',
    render: (_: string, agence) => (
      <TitleWithAvatar
        size={'20px'}
        borderRadius={'2px'}
        avatarSrc={agence.logoUrl}
        title={agence.name ?? 'No Name'}
      />
    ),
  },
  {
    title: 'N° Téléphone',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    responsive: ['md'],
    render: (phoneNumber: string) => (
      <a onClick={e => e.stopPropagation()} href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </a>
    ),
  },
];
