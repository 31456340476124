import React from 'react';
import { Alert } from 'antd';

export const AccessDenied: React.FC = () => (
  <div className="padding-3rem">
    <Alert
      message="Accès refusé"
      description={
        <small>Vous n'avez pas l'autorisation d'accéder à cette page</small>
      }
      type="error"
      showIcon
    />
  </div>
);
