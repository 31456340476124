import React from 'react';
import { Input } from 'antd';
import './number-input.component.less';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

interface NumberInputProps {
  numberValue?: boolean;
  isDecimal?: boolean;
  prefix?: string;
  placeholder?: string;
  allowMinus?: boolean;
  allowPlus?: boolean;
  value?: number | string;
  onChange?: (value?: number | string) => void;
  icon?: React.ReactNode;
  formatter?: (value?: number | string) => string;
  parser?: (value?: string) => number | string | undefined;
  style?: React.CSSProperties;
  disabled?: boolean;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
}

export const defaultParser = (value?: string, numberValue = false) => {
  if (isEmpty(value) || isNil(value)) return undefined;
  const result = numberValue ? +value : value;
  if (!isNaN(result)) {
    return result;
  }
};

export const NumberInput: React.FC<NumberInputProps> = ({
  numberValue = false,
  isDecimal = true,
  prefix,
  placeholder = '',
  allowMinus = false,
  allowPlus = false,
  value,
  onChange,
  icon,
  formatter = value => value,
  parser = value => defaultParser(value, numberValue),
  style,
  disabled = false,
  inputMode = isDecimal ? 'decimal' : 'numeric',
}) => {
  const onValueChange = React.useCallback(
    (value?: string) => {
      if (!onChange) {
        return;
      }
      onChange(parser(value));
    },
    [onChange, parser]
  );

  return (
    <Input
      value={formatter(value)}
      onChange={e => onValueChange(e.target.value)}
      className={'numberInput'}
      onKeyDown={e =>
        (e.key === 'ArrowUp' ||
          e.key === 'ArrowDown' ||
          e.key === 'e' ||
          e.key === 'E' ||
          (!allowMinus && e.key === '-') ||
          (!isDecimal && e.key === ',') ||
          (!isDecimal && e.key === '.') ||
          (!allowPlus && e.key === '+')) &&
        e.preventDefault()
      }
      type={'number'}
      inputMode={inputMode}
      placeholder={placeholder}
      prefix={prefix}
      addonAfter={icon}
      style={style}
      disabled={disabled}
      allowClear
    />
  );
};
