import React from 'react';
import { Modal, Spin } from 'antd';

interface ILoginLoadingProps {
  loadingMessage: string | boolean;
  visible?: boolean;
}

export const PageLoading: React.FC<ILoginLoadingProps> = props => (
  <Modal
    title={false}
    children={
      <>
        <br />
        <Spin spinning size="large" />
        <div>
          <small>{`${props.loadingMessage}`}</small>
        </div>
      </>
    }
    footer={false}
    centered
    visible={props.visible === undefined ? true : props.visible}
    width={250}
    closable={false}
    bodyStyle={{ textAlign: 'center' }}
    zIndex={9999}
    maskStyle={{ background: 'white' }}
  />
);
