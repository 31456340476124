import React from 'react';
import { Project } from '../../domain/project/project.domain';
import { PageHeader, Tabs, Button } from 'antd';
import { useMediaQueries } from '../../style/media-query.context';
import { ProjectSalesTable } from './project-sales-table.component';
import { HddOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ProjectRegister } from '../../domain/project/redux/project-register';
import { FilesTable } from '../file/files-table.component';

export interface ProjectPageProps {
  project: Project;
  onEdit?: (projectId: string) => void;
  onDelete?: (project: Project) => void;
  onBack?: () => void;
}

export const ProjectPageComponent: React.FC<ProjectPageProps> = ({
  project,
  onBack,
}) => {
  const dispatch = useDispatch();
  const { mdDown } = useMediaQueries();

  const archiveProject = (archived: boolean) => {
    dispatch(
      ProjectRegister.documentActions.update.started({
        before: project,
        after: { archived },
      })
    );
  };

  return (
    <PageHeader
      onBack={mdDown ? onBack : undefined}
      title={project?.name}
      subTitle={mdDown ? undefined : project?.code}
      extra={
        project.archived ? (
          <Button icon={<HddOutlined />} onClick={() => archiveProject(false)}>
            Désarchiver
          </Button>
        ) : (
          <Button
            type={'primary'}
            icon={<HddOutlined />}
            danger
            onClick={() => archiveProject(true)}
          >
            Archiver
          </Button>
        )
      }
    >
      <Tabs>
        <Tabs.TabPane tab="Fichiers" key="files">
          <FilesTable project={project} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Agents" key="sales">
          <ProjectSalesTable project={project} />
        </Tabs.TabPane>
      </Tabs>
    </PageHeader>
  );
};
