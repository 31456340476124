import actionCreatorFactory from 'typescript-fsa';

const factory = actionCreatorFactory('NOTIFICATION');

export const AuthActionTypes = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export interface Message {
  message: string;
}

export const NotificationActions = {
  success: factory<Message>(AuthActionTypes.SUCCESS),
  warning: factory<Message>(AuthActionTypes.WARNING),
  error: factory<Message>(AuthActionTypes.ERROR),
};
