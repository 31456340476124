import React from 'react';
import { Menu, Grid } from 'antd';
import { useHistory } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

export const LeftMenu = () => {
  const history = useHistory();
  const { md } = Grid.useBreakpoint();
  return (
    <Menu mode={md ? 'horizontal' : 'inline'}>
      <Menu.Item
        key="home"
        icon={<HomeOutlined />}
        onClick={() => history.push('/')}
      />
      <Menu.Item key="projects" onClick={() => history.push('/projects')}>
        Projets
      </Menu.Item>
      <Menu.Item key="agences" onClick={() => history.push('/agences')}>
        Agences
      </Menu.Item>
      <Menu.Item key="catalog" onClick={() => history.push('/catalog')}>
        Catalogue
      </Menu.Item>
    </Menu>
  );
};
