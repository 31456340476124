import { Entity } from '../utils/entity';
import { EntityRenderDataType } from '../types/entity-render-data-type';
import { Address } from '../types/address';

export const agenceRenderData = (agence: Agence): EntityRenderDataType => ({
  key: agence.id,
  title: `${agence.name}`,
  subTitle: agence.phoneNumber ?? '',
  avatarSrc: agence.logoUrl,
});

export interface Agence extends Entity {
  name?: string;
  phoneNumber?: string;
  address?: Address;
  logoUrl?: string;
}
