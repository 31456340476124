import React from 'react';
import {
  CollectionActions,
  CollectionReadParams,
} from '../redux/actions/utils/generate-collection-actions';
import { useDispatch } from 'react-redux';
import lowerCase from 'lodash/lowerCase';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { removeWhiteSpaces } from '../helpers/strings';
import { Entity } from '../domain/utils/entity';

export const useCollectionSubscribedSearch = <
  DocumentType extends Entity,
  ReadParamType extends CollectionReadParams<DocumentType>
>(
  collectionActions: CollectionActions<ReadParamType, DocumentType>,
  readParams: ReadParamType,
  initialLimit = 10,
  initialStartSubscribe = true
) => {
  const dispatch = useDispatch();
  const [startSubscribe, setStartSubscribe] = React.useState(
    initialStartSubscribe
  );
  const [limit, setLimit] = React.useState(initialLimit);
  const [searchValue, setSearchValue] = React.useState<string>();

  React.useEffect(() => {
    if (!startSubscribe) {
      return;
    }
    const params = {
      ...readParams,
      pagination: {
        page: 0,
        limit,
      },
    };
    params.filters = (params.filters ?? []).filter(
      filter => filter.field !== '_search'
    );
    if (!isEmpty(searchValue)) {
      params.filters.push({
        field: '_search',
        op: 'array-contains',
        value: removeWhiteSpaces(lowerCase(searchValue)),
      });
    }
    if (!params.orderBy && (params.filters || []).length === 0) {
      params.orderBy = {
        field: 'modificationDate',
        direction: 'desc',
      };
    }
    dispatch(collectionActions.subscribe.started(params));
    return () => {
      dispatch(collectionActions.unsubscribe(params));
    };
  }, [
    dispatch,
    collectionActions,
    limit,
    readParams,
    searchValue,
    startSubscribe,
  ]);
  return {
    limit,
    setStartSubscribe,
    setLimit,
    setSearchValue: debounce(setSearchValue, 500),
  };
};
