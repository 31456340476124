import React from 'react';
import { FormInstance } from 'antd/es/form';
import { Form, Input } from 'antd';
import { Project } from '../../domain/project/project.domain';
import { AgenceSelectComponent } from '../agence/agence-select.component';

interface ProjectFormProps {
  newProjectId?: string;
  agenceId?: string;
  project?: Project;
  form?: FormInstance;
}

export const ProjectForm: React.FC<ProjectFormProps> = ({ agenceId }) => {
  return (
    <>
      <Form.Item name={'agenceId'} label={'Agence'} hidden={!!agenceId}>
        <AgenceSelectComponent />
      </Form.Item>
      <Form.Item label={'Code du projet'} name={'code'}>
        <Input />
      </Form.Item>
      <Form.Item label={'Nom du projet'} name={'name'}>
        <Input />
      </Form.Item>
    </>
  );
};
