import { reducerWithInitialState } from 'typescript-fsa-reducers';
import firebase from 'firebase/app';
import immutable from 'immutability-helper';
import { DatabaseActions } from '../actions/database.actions';
import { Properties } from '../../utils/types';

export const DEFAULT_REF = 'ref';

export interface RefState<DocumentType = any> {
  loading?: boolean;
  error?: any;
  snapshot?: firebase.database.DataSnapshot;
  document?: DocumentType;
  subscribed?: boolean;
}

export interface RefStates extends Properties<RefState> {}

export const initialRefState = {
  loading: false,
  error: undefined,
  snapshot: undefined,
  document: undefined,
  subscribed: false,
};

const databaseReducer = {
  database: (reducerWithInitialState<RefStates>({})
    .case(DatabaseActions.read.started, (state, action) => {
      return immutable(state, {
        [action ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              loading: true,
              error: undefined,
              snapshot: undefined,
              document: undefined,
            },
          }),
      });
    })
    .case(DatabaseActions.read.done, (state, action) => {
      return immutable(state, {
        [action.params ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              loading: false,
              error: undefined,
              snapshot: action.result,
              document: action.result.val(),
            },
          }),
      });
    })
    .case(DatabaseActions.read.failed, (state, action) => {
      return immutable(state, {
        [action.params ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              loading: false,
              error: action.error.message,
              snapshot: undefined,
              document: undefined,
            },
          }),
      });
    })
    .case(DatabaseActions.subscribe.started, (state, action) => {
      return immutable(state, {
        [action ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              loading: true,
              error: undefined,
              snapshot: undefined,
              subscribed: false,
            },
          }),
      });
    })
    .case(DatabaseActions.subscribe.done, (state, action) => {
      return immutable(state, {
        [action.params ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              loading: false,
              error: undefined,
              snapshot: undefined,
              subscribed: true,
            },
          }),
      });
    })
    .case(DatabaseActions.subscribe.failed, (state, action) => {
      return immutable(state, {
        [action.params ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              loading: false,
              subscribed: false,
              error: action.error.message,
            },
          }),
      });
    })
    .case(DatabaseActions.unsubscribe, (state, action) =>
      immutable(state, {
        [action ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              loading: false,
              subscribed: false,
              error: undefined,
            },
          }),
      })
    )
    .case(DatabaseActions.cleanState, (state, action) =>
      immutable(state, {
        [action ?? DEFAULT_REF]: refState =>
          immutable(refState || initialRefState, {
            $merge: {
              error: undefined,
              document: undefined,
              subscribed: false,
              loading: false,
            },
          }),
      })
    )
    .case(DatabaseActions.resetState, (state, action) =>
      immutable(state, {
        [action ?? DEFAULT_REF]: () => initialRefState,
      })
    ) as any) as RefStates,
};

export default databaseReducer;
