import lowerCase from 'lodash/lowerCase';

export const removeLeadingZeros = (value: string) => {
  return value?.replace(/^0+/g, '');
};

export const removeNonDigits = (value: string) => {
  return value?.replace(/[^[0-9]]/g, '');
};

export const removeWhiteSpaces = (value: string) => {
  return value?.replace(/\s/g, '');
};

export const lowerCaseRemoveSpecialChars = (value: string) => {
  return lowerCase(value);
};
